import { AdviesBoxFormik, cleanFilename, LabeledText, LabeledTextInput, useRequestInit } from "adviesbox-shared";
import classNames from "classnames";
import { useFormikContext } from "formik";
import moment from "moment";
import React, { ReactElement, useState } from "react";
import { Button } from "react-bootstrap";
import { DownloadResultApiModel } from "../../.generated/bemiddeling/bemiddelingtypes";
import Modal from "../../shared/components/modal/Modal";
import classes from "../documentBeheer.module.scss";
import {
  bestandsIndelingTypes,
  documentenBeheerItem,
  DocumentenBeheerItem,
  DocumentenBeheerState
} from "../infra/documentBeheer-schema";
import { DocumentPreviewBody } from "./document-preview-body";

type PreviewModalProps = {
  path: string;
  blobLocatie: any;
  closeModal?: () => void;
};

export const PreviewModal = ({ path, closeModal, blobLocatie }: PreviewModalProps): ReactElement => {
  const { getFieldProps, setFieldValue } = useFormikContext<DocumentenBeheerState>();
  const [downloading, setDownloading] = useState(false);
  const { params, settings, requestInit } = useRequestInit();
  const currentFile = getFieldProps(path);

  return (
    <AdviesBoxFormik<DocumentenBeheerItem>
      initialValues={currentFile.value}
      validationSchema={documentenBeheerItem}
      closeModal={closeModal}
      onSave={file => {
        const extenstion = `.${file.bestandsIndeling}`;
        const fileNameIncludingExtension = file.bestandsnaam + extenstion;
        file.bestandsNaamInclusiefIndeling = fileNameIncludingExtension;
        setFieldValue(currentFile.name, file);
        setFieldValue("updatedFile", file);
      }}
      render={({ submitForm, values }): ReactElement => {
        /* istanbul ignore next */
        const downloadDocument = async (): Promise<void> => {
          if (downloading) return;
          setDownloading(true);
          const urlCreation = values?.bestandLocatie
            ? `${settings.klantdossiersBemiddelingOrigin}/Document/klantsamenstelling/${
                params.adviesdossier
              }/download/${blobLocatie}/${`${cleanFilename(values.bestandsnaam)}.${values.bestandsIndeling}`}`
            : `${settings.klantdossiersBemiddelingOrigin}/Document/klantsamenstelling/${params.adviesdossier}/download/${blobLocatie}`;
          const res = await fetch(urlCreation, requestInit);
          const json: DownloadResultApiModel = await res.json();
          const fileLocation = json.fileDownloadUrls?.pop();

          setDownloading(false);

          if (!fileLocation) return;
          window.open(fileLocation);
        };

        return (
          <div>
            <Modal
              title={`Informatie`}
              onCancelClick={closeModal}
              onSubmitClick={submitForm}
              body={
                <div>
                  <LabeledTextInput name={`bestandsnaam`} caption="Naam" />
                  <LabeledTextInput readonly={true} name={`bestandsIndeling`} caption="Bestandsformaat" />
                  <LabeledTextInput readonly={true} name={`medewerkerNaam`} caption={`Ge\xFCpload door`} />
                  <div className={`${classes.height_fix_labeledText} ${classes.width_fix_labeledText}`}>
                    <LabeledText
                      value={moment(currentFile.value.laatstGewijzigd?.toString()).format("DD-MM-YYYY HH:mm")}
                      name={`laatstGewijzigd`}
                      caption="Aanmaakdatum"
                    />
                  </div>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    {values.bestandsIndeling === bestandsIndelingTypes.DOCX ? (
                      <div className={classNames(classes.voorvertoning, "text-center")}>
                        <h2>Voor dit bestand is geen voorvertoning mogelijk.</h2>
                        <br />
                        <Button onClick={downloadDocument} className="mt-1">
                          Bestand downloaden
                        </Button>
                      </div>
                    ) : (
                      <DocumentPreviewBody filename={values.bestandsnaam} document={currentFile.value} />
                    )}
                  </div>
                </div>
              }
            />
          </div>
        );
      }}
    />
  );
};
