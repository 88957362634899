import * as Yup from "yup";
import { getGeneralTextResources } from "../../utils/text-resources-algemeen";
/* istanbul ignore file */
export const adresSchema = Yup.object().shape({
  straat: Yup.string().default(""),
  huisnummer: Yup.number()
    .nullable()
    .default(null)
    .test({
      message: getGeneralTextResources("ErrorHuisnummer"),
      test: function(value: number): boolean {
        if (value) {
          return value < 32767;
        }
        return true;
      }
    }),
  toevoeging: Yup.string().default(""),
  locatie: Yup.string().default(""),
  plaats: Yup.string().default(""),
  landCode: Yup.string().default("NL"),
  postcode: Yup.string()
    .default("")
    .when("landCode", {
      is: (val): boolean => val === "NL",
      then: Yup.string().matches(/^$|^[1-9][0-9]{3}[\s]?[A-Za-z]{2}$/i, getGeneralTextResources("ErrorPostcode"))
    })
});

export default adresSchema;
