import isEqual from "react-fast-compare";
import { pathToString } from "./path-to-string-helper";

export type HasChanged<T> = Extract<T, object> extends never
  ? ChangedProp
  : HasChangedObj<T> & Omit<ChangedProp, keyof T>;
type ChangedProp = { changed: boolean };
type HasChangedObj<T> = { [P in keyof T]: HasChanged<T[P]> };

const pathInObjForCompare = (obj: { [index: string]: any }, pathArr: string[], _debug: string): any => {
  let i = 0;
  if (typeof obj !== "object") return `pathInObj: ${typeof obj} is not an object`;
  for (; i < pathArr.length - 1; i++) {
    obj = obj[pathArr[i]];
    if (obj === null || typeof obj !== "object") {
      return `pathInObj: missing property for path "${pathToString(pathArr)}"`;
    }
  }

  return obj[pathArr[i]];
};

const compare = <T>(curr: Readonly<T>, prev: Readonly<T>, pathArr: string[]): boolean =>
  pathArr.length === 0
    ? !isEqual(curr, prev)
    : !isEqual(pathInObjForCompare(curr, pathArr, "curr"), pathInObjForCompare(prev, pathArr, "prev"));

function hasChangedInternal<T>(pathArr: string[], curr: Readonly<T>, prev: Readonly<T>): HasChanged<T> {
  return new Proxy(
    {},
    {
      get(target: { [index: string]: any }, name: string): HasChanged<T> | boolean {
        if (name === "changed" && typeof target["changed"] === "undefined") {
          return compare(curr, prev, pathArr);
        }
        return hasChangedInternal([...pathArr, name], curr, prev);
      }
    }
  ) as HasChanged<T>;
}
export function hasChanged<T>(curr: T, prev: T): HasChanged<T> {
  return hasChangedInternal([], curr, prev);
}
