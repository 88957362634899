import { ReactElement } from "react";
import { createSpanWithId } from "adviesbox-shared";
import { Column } from "react-table-6";
import moment from "moment";

export const laatsteDossierColumns: Column[] = [
  {
    Header: "Laatst geopend",
    accessor: "laatstGeopend",
    id: "openedOn",
    Cell: (c): ReactElement => {
      const val = moment(c.original.laatstGeopend.toString()).format("DD-MM-YYYY HH:mm");
      return createSpanWithId(c.index, 0, val, val, "laatste-dossiers");
    }
  },
  {
    Header: "Klant",
    accessor: "klant",
    id: "Klant",
    minWidth: 180,
    Cell: (c): ReactElement => createSpanWithId(c.index, 1, c.original.klant, c.original.klant, "laatste-dossiers")
  }
];
