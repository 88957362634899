import { createContext } from "react";
import { Medewerker as M } from "../../.generated/licenties/licentiestypes";
import { userDetailsSchema } from "./user-details-schema";

export interface Medewerker extends M {
  medewerkerId: string;
  organisatieId: string;
  isAuthedForFase2: boolean;
}

export const defaultUserDetails = {
  userDetails: userDetailsSchema.default(),
  setUserDetails: (_userDetails: Medewerker): void => { }
};

const UserDetailsContext = createContext(defaultUserDetails);

export default UserDetailsContext;
