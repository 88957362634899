import * as Yup from "yup";
import { MedewerkerGeslacht, MedewerkerKlantdossierRechtenOrganisatie, MedewerkerKlantdossierRechtenVestigingen } from "../../.generated/licenties/licentiestypes";
import { yupEnum } from "../utils/yup-enum";

export const userDetailsSchema = Yup.object().shape({
    voornaam: Yup.string().nullable().default(""),
    tussenvoegsels: Yup.string().nullable().default(""),
    achternaam: Yup.string().default(""),
    roepnaam: Yup.string().nullable().default(null),
    voorletters: Yup.string().default(""),
    telefoon: Yup.string().nullable().default(null),
    telefoonWerk: Yup.string().nullable().default(null),
    telefoonMobiel: Yup.string().nullable().default(null),
    geslacht: yupEnum(MedewerkerGeslacht).nullable().default(MedewerkerGeslacht.Man),
    email: Yup.string().default(""),
    functie: Yup.string().nullable().default(null),
    platformId: Yup.string().nullable().default(null),
    klantId: Yup.string().nullable().default(null),
    isActief: Yup.boolean().nullable().default(false),
    klantdossierRechtenVestigingen: yupEnum(MedewerkerKlantdossierRechtenVestigingen).nullable().default(null),
    klantdossierRechtenOrganisatie: yupEnum(MedewerkerKlantdossierRechtenOrganisatie).nullable().default(null),
    medewerkerId: Yup.string().default(""),
    organisatieId: Yup.string().default(""),
    isAuthedForFase2: Yup.boolean().default(false)
});