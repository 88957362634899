import { createContext } from "react";

export type logger = {
  setAdviesDossierId: (
    adviesDossierId: string | null,
    klantdossierId: string | null,
    vestigingId: string | null
  ) => void;
};

export const contextData: logger = {
  setAdviesDossierId: () => {}
};

const OpenDossierLogContext = createContext(contextData);
export default OpenDossierLogContext;
