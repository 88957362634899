import { LocalDate } from "@js-joda/core";
import { nullableLocalDateSchema} from "adviesbox-shared"
import * as Yup from "yup";

export enum bestandsIndelingTypes {
  MAP = "Map",
  PDF = "pdf",
  DOCX = "docx",
  PNG = "png",
  JPG = "jpg",
  JPEG = "jpeg",
  XLSX = "xlsx"
}

export interface DocumentenBeheerSchema {
  bestandsnaam: string;
  bestandsNaamInclusiefIndeling: string;
  laatstGewijzigdString: string | null;
  laatstGewijzigd: LocalDate | null;
  kenmerk: string | null;
  bestandsIndeling: string | null;
  bestandLocatie: string | null;
  originLocatie: string | null;
  documentType: string | null;
  bestandBlobLocatie: string | null;
  klantsamenstellingDocumentId: string | null;
  medewerkerId: string;
  medewerkerNaam: string;
  children: this[];
  isNew: boolean;
}
export const documentenBeheerItem = Yup.object({
  bestandsnaam: Yup.string().default(""),
  bestandsNaamInclusiefIndeling: Yup.string().default(""),
  laatstGewijzigdString: Yup.string()
    .nullable()
    .default(null),
  laatstGewijzigd: nullableLocalDateSchema,
  kenmerk: Yup.string()
    .nullable()
    .default(null),
  bestandsIndeling: Yup.string()
    .nullable()
    .default(""),
  bestandLocatie: Yup.string()
    .nullable()
    .default(""),
  originLocatie: Yup.string()
    .nullable()
    .default(""),
  documentType: Yup.string()
    .nullable()
    .default(""),
  bestandBlobLocatie: Yup.string()
    .nullable()
    .default(""),
  klantsamenstellingDocumentId: Yup.string()
    .nullable()
    .default(""),
  medewerkerId: Yup.string().default(""),
  medewerkerNaam: Yup.string().default(""),
  children: Yup.array<DocumentenBeheerSchema>().default([]),
  isNew: Yup.boolean().default(false)
});
export const documentBeheerSchema = Yup.object({
  documentenbeheer: Yup.array(documentenBeheerItem).default([
    {
      ...documentenBeheerItem.default(),
      bestandsnaam: "WerkgeversverklaringPartner",
      kenmerk: "CH",
      laatstGewijzigd: LocalDate.now()
        .plusMonths(0)
        .plusDays(-10),
      bestandsIndeling: bestandsIndelingTypes.DOCX
    },
    {
      ...documentenBeheerItem.default(),
      bestandsnaam: "WerkgeversverklaringAanvrager",
      kenmerk: "HA",
      laatstGewijzigd: LocalDate.now(),
      bestandsIndeling: bestandsIndelingTypes.PDF
    },
    {
      ...documentenBeheerItem.default(),
      bestandsnaam: "map",
      kenmerk: "HA",
      laatstGewijzigd: LocalDate.now(),
      bestandsIndeling: bestandsIndelingTypes.MAP,
      documentType: "Folder"
    }
  ]),
  updatedFile: documentenBeheerItem.nullable(),
  fileToBeRemoved: documentenBeheerItem.nullable(),
  newFolder: documentenBeheerItem.nullable(),
  documentenInView: Yup.number().default(10)
});

export type DocumentenBeheerType = Yup.InferType<typeof documentBeheerSchema>;
export type DocumentenBeheerProps = Yup.InferType<typeof documentBeheerSchema>;
export type DocumentenBeheerState = Yup.InferType<typeof documentBeheerSchema>;
export type DocumentenBeheerItem = Yup.InferType<typeof documentenBeheerItem>;
export type FileType = Yup.InferType<typeof documentenBeheerItem>;
