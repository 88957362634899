import { logErrorToApplicationInsights } from "adviesbox-shared";

/*istanbul ignore file */
export const pdfPreview = async (url: string): Promise<any> => {
  const response = await fetch(url);
  if (!response || !response.ok) {
    logErrorToApplicationInsights(Error(`Fout bij aanroep van web-api '${url}'. Resultaat bevat geen PDF.`));
    return null;
  }

  return await response.blob();
};
