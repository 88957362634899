import { cleanFilename, LoadingSpinner, logErrorToApplicationInsights, useRequestInit } from "adviesbox-shared";
import React, { ReactElement, useEffect, useRef, useState } from "react";
import { hasJSONResponse } from "../../shared/utils/ajax";
import { FileViewer } from "../btnModals/file-upload-test/fileviewer";
import { DocumentenBeheerItem } from "../infra/documentBeheer-schema";
import { pdfPreview } from "../infra/documenten-preview-api";

type DocumentPreviewModalProps = {
  document: DocumentenBeheerItem;
  filename?: string;
};
type ExtensionType = "pdf" | "docx" | "png" | "jpg" | "jpeg";

export const DocumentPreviewBody = ({ document, filename }: DocumentPreviewModalProps): ReactElement => {
  const { user, params, settings } = useRequestInit();
  const [pdfData, setPdfData] = useState<File | null>();
  const extension = useRef<ExtensionType>();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const vestigingId = params.vestiging;

  useEffect(() => {
    if (pdfData) {
      setLoading(false);
      return;
    }

    const fetchData = async (): Promise<void> => {
      if (vestigingId) {
        const bestandLocatie = `${cleanFilename(document.bestandsnaam)}.${document.bestandsIndeling}`;
        const url = `${settings.klantdossiersBemiddelingOrigin}/Document/klantsamenstelling/${params.adviesdossier}/download/${document.bestandBlobLocatie}/${bestandLocatie}`;

        const response = await fetch(url, {
          method: "GET",
          headers: {
            authorization: user ? `${user.token_type} ${user.access_token}` : "",
            "Ocp-Apim-Subscription-Key": settings.OcpApimSubscriptionKey,
            "Content-Type": "application/pdf",
            vestigingId
          }
        });

        if (response || hasJSONResponse(response)) {
          const jsonResponse = await response.json();
          const indeling = document.bestandsIndeling?.toLowerCase() || "pdf";

          if (indeling && jsonResponse.fileDownloadUrls[0]) {
            extension.current = indeling as ExtensionType;

            setPdfData(await pdfPreview(jsonResponse.fileDownloadUrls[0]));
            return;
          }
        }
        logErrorToApplicationInsights(Error(`Fout bij aanroep van web-api '${url}'. Resultaat bevat geen document`));
        setError(true);
        return;
      }
    };
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    if (!pdfData) fetchData();
  }, [
    params.adviesdossier,
    pdfData,
    settings,
    user,
    document.bestandBlobLocatie,
    vestigingId,
    document.bestandsIndeling,
    document.bestandsnaam
  ]);

  if (pdfData && extension.current && !loading) {
    return <FileViewer file={pdfData} filename={filename} extension={extension.current} />;
  }

  if (pdfData === null || error) {
    return <p>Het document kan niet geladen worden.</p>;
  }

  return <LoadingSpinner size="S" />;
};
