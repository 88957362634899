import {
  AuthContext,
  DataGrid,
  DateInput,
  Icon,
  LabeledSelectInput,
  PostcodeInput,
  RadioInputGroup,
  SelectInput,
  SettingsContext,
  TextInput,
  useRequestInit
} from "adviesbox-shared";
import { default as classNames, default as classnames } from "classnames";
import { connect, FormikContextType, useFormikContext } from "formik";
import React, { ReactElement, useCallback, useContext, useRef, useState } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { Column } from "react-table-6";
import useAbortableFetch from "use-abortable-fetch";
import { ZoekResultaatSorteerOptions } from "../../.generated/forms/formstypes";
import { MedewerkerOrganisatieOutput, VestigingenOutput } from "../../.generated/licenties/licentiestypes";
import ParamRouteContext from "../../shared/paramrouting/paramrouting-context";
import { Direction, LabelValuePairs } from "../../shared/types";
import UserDetailsContext from "../../shared/user-details/user-details-context";
import { hasValue } from "../../shared/utils/helpers";
import { mapVestigingDlToUi } from "../infra/map-vestigingen";
import { getZoekenTextResources } from "../infra/zoeken-resources";
import { searchClients } from "../infra/zoekscherm-api";
import { SearchCategoriesAdvies, ZoekResultaatType, ZoekschermState } from "../infra/zoekscherm-schema";
import SelectedClientModal from "../selected-client-modal/selected-client-modal";
import { ZoekResultatenColumns } from "./zoeken-helpers/zoeken-columns";
import { PaginationComponent } from "./zoeken-helpers/zoeken-pagination";
import { filterRecordsBasedOnRights } from "./zoeken-helpers/zoeken-rechten-filter";
import { mapZoekresultaten } from "./zoeken-helpers/zoeken-resultaat-mapper";
import classes from "./zoeken.module.scss";

type ZoekenPropType = {
  showTimeoutWarningInitially?: boolean;
  disableTimeout?: boolean;
  fakeTimeoutFn?(): void;
  timeouttime?: number;
};
export const filterIfSearchByName = (searchCategory: string, huidigeKolomen: Column[]): Column[] => {
  if (searchCategory === SearchCategoriesAdvies.Naam)
    return huidigeKolomen.filter(v => v.id !== "geboortedatum" && v.id !== "woonplaats" && v.id !== "postcode");

  return huidigeKolomen;
};

const Zoeken = ({
  disableTimeout = false,
  timeouttime,
  fakeTimeoutFn
}: ZoekenPropType & {
  formik: FormikContextType<ZoekschermState>;
} & RouteComponentProps): ReactElement => {
  const { setFieldValue, values } = useFormikContext<ZoekschermState>();
  const maximumTimeout = hasValue(timeouttime) ? timeouttime : 30;
  const [isModalOpen, setModalOpen] = useState(false);
  const [abortControllerSearch, setAbortControllerSearch] = useState(new AbortController());
  const [loading, setLoading] = useState(false);
  const settings = useContext(SettingsContext);
  const { user } = useContext(AuthContext);
  const params = useContext(ParamRouteContext);
  const { userDetails } = useContext(UserDetailsContext);
  const [initialRequest, setInitialRequest] = useState(true);
  const [showTimeoutWarning, setShowTimeoutWarning] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [showAdvancedSearch, setShowAdvancedSearch] = useState(false);
  const { requestInit } = useRequestInit();

  const medewerkerUrl = user && user.profile ? `${settings.licentiesOrigin}/Medewerkers/current` : null;
  const medewerker = useAbortableFetch<MedewerkerOrganisatieOutput>(medewerkerUrl, requestInit);
  const vestigingenUrl =
    medewerker.data && typeof medewerker.data !== "string"
      ? `${settings.licentiesOrigin}/Medewerkers/${medewerker.data.medewerkerId}/Vestigingen`
      : null;

  const vestiging = useAbortableFetch<VestigingenOutput>(vestigingenUrl, requestInit);
  const timeoutRef = useRef<NodeJS.Timeout>();
  const alleVestigingen = "ALL";

  let vestigingenOptions: LabelValuePairs = [];
  if (
    !vestigingenOptions.length &&
    vestiging.data &&
    typeof vestiging.data !== "string" &&
    typeof vestiging.data !== "undefined"
  ) {
    const vestigingen = mapVestigingDlToUi(vestiging.data);

    vestigingenOptions = [{ label: "Alle vestigingen", value: alleVestigingen }].concat(
      vestigingen?.vestigingen
        .sort(c => (c.isHoofdvestiging ? 1 : -1))
        .map(c => {
          return { label: c.naam, value: c.id || /* istanbul ignore next */ "" };
        }) || []
    );
  }

  /* istanbul ignore next */
  const getFormattedPostcode = (postcode: string): string => {
    postcode = postcode.toUpperCase().replace(/\s/g, "");
    const letters = postcode.slice(4, 6);
    const numbers = postcode.slice(0, 4);

    if (numbers.charAt(0) !== "") {
      postcode = `${numbers} ${letters}`;
    }

    return postcode;
  };

  const getMatchingClients = async (page?: number, sorting?: ZoekResultaatSorteerOptions): Promise<void> => {
    // new search, disable existing timers
    timeoutRef.current && clearTimeout(timeoutRef.current);
    /* istanbul ignore next */
    if (!user) {
      return;
    }

    setLoading(true);
    setInitialRequest(false);
    setShowTimeoutWarning(false);

    !abortControllerSearch.signal.aborted && abortControllerSearch.abort();
    const newAbortController = new AbortController();

    /* istanbul ignore next */
    const timeoutFn = (): void => {
      !abortControllerSearch.signal.aborted && abortControllerSearch.abort();
      setShowTimeoutWarning(true);
      setLoading(false);
    };

    if (!disableTimeout) {
      timeoutRef.current = setTimeout(() => {
        fakeTimeoutFn ? fakeTimeoutFn() : timeoutFn();
      }, (maximumTimeout ?? 0) * 1000);
    }

    setAbortControllerSearch(newAbortController);
    const result = await searchClients(
      values.zoeken.searchValue,
      settings,
      params.vestiging,
      user,
      newAbortController.signal,
      page || pageNumber,
      sorting || values.zoeken.sorteren,
      {
        geboortedatum: values.zoeken.geboortedatum,
        woonplaats: values.zoeken.woonplaats,
        postcode: values.zoeken.postcode ? getFormattedPostcode(values.zoeken.postcode) : null,
        doorzoekAlleAdviezen: values.zoeken.optieDossiers === "1",
        zoekInVestiging: values.zoeken.vestiging === alleVestigingen ? null : values.zoeken.vestiging
      }
    );

    /* istanbul ignore else */
    if (result) {
      timeoutRef.current && clearTimeout(timeoutRef.current);
      const mappedResult = mapZoekresultaten(result?.zoekresultaten ?? []);

      const zoekresultatenMetToegangsrechten = filterRecordsBasedOnRights(
        mappedResult,
        userDetails.medewerkerId,
        params.vestiging,
        userDetails.klantdossierRechtenVestigingen,
        userDetails.klantdossierRechtenOrganisatie
      );

      setShowTimeoutWarning(false);

      setFieldValue("zoeken.zoekresultaten", mappedResult);
      setFieldValue("zoeken.zoekresultatenMetToegangsrechten", zoekresultatenMetToegangsrechten);

      setFieldValue("totalNumberOfPages", result?.totalNumberOfPages ?? 0);
      setFieldValue("totalNumberOfResults", result?.totalNumberOfResults ?? 0);
    }

    setLoading(false);
  };

  const resetResults = (): void => {
    !abortControllerSearch.signal.aborted && abortControllerSearch.abort();
    setInitialRequest(true);
    setFieldValue("zoeken.zoekresultaten", []);
    setFieldValue("zoeken.zoekresultatenMetToegangsrechten", []);
    setLoading(false);
  };

  const keyUpEvent = (event?: KeyboardEvent | React.KeyboardEvent<HTMLDivElement>): void => {
    if (event && event.key !== "Enter") {
      return;
    }
    setPageNumber(1);

    /* eslint-disable-next-line @typescript-eslint/no-floating-promises */
    getMatchingClients();
  };

  const searchResultRowProps = (
    zoekresultaat: ZoekResultaatType,
    _: Column,
    index: number
  ): {
    onClick: () => void;
  } => ({
    onClick: (): void => {
      setFieldValue("zoeken.selectedClient", { ...zoekresultaat, index });
      setModalOpen(true);
    }
  });

  /* istanbul ignore next */
  const validSearchInput = useCallback((): boolean => {
    return (
      (values.zoeken.searchValue !== "" && values.zoeken.searchValue.length >= 2) ||
      (hasValue(values.zoeken.postcode) && values.zoeken.postcode.replace(/_/g, "").replace(/\s/g, "").length === 6) ||
      hasValue(values.zoeken.geboortedatum) ||
      (hasValue(values.zoeken.woonplaats) && values.zoeken.woonplaats.length >= 2)
    );
  }, [values.zoeken.geboortedatum, values.zoeken.postcode, values.zoeken.searchValue, values.zoeken.woonplaats]);

  return (
    <div className="container" style={{ marginBottom: "20px" }}>
      <div className="row">
        <div className={`flex offset-2 col-8 d-flex flex-row`}>
          <div className={"d-flex flex-row w-100"} onKeyUp={event => keyUpEvent(event)}>
            <>
              <TextInput
                autoComplete="off"
                errorMessageClassName={classes.search_error}
                name="zoeken.searchValue"
                fullWidth={true}
                className={classes.search_input}
                placeholder={"Bestaande klant zoeken"}
              />
            </>
          </div>
          <button
            id="geavanceerd"
            type="button"
            data-testid={"zoeken.geavanceerd"}
            onClick={() => {
              setShowAdvancedSearch(!showAdvancedSearch);
            }}
            className={classnames("ml-4", classes.btn_advanced)}
            style={{ background: "white" }}
          >
            |&nbsp;&nbsp;geavanceerd&nbsp;&nbsp;
            {!showAdvancedSearch && <Icon name="chevron" alt="geavanceerd" iconSize="xs" />}
            {showAdvancedSearch && <Icon name="chevronup" alt="geavanceerd" iconSize="xs" />}
          </button>
          <button
            id="zoeken"
            data-testid={"zoeken.action"}
            onClick={() => {
              if (!validSearchInput()) return;
              setPageNumber(1);
              return getMatchingClients(1);
            }}
            className={classnames(
              "btn btn-primary ml-4",
              classes.btn_search,
              `${!validSearchInput() ? "disabled" : ""}`
            )}
            disabled={!validSearchInput()}
            type="button"
          >
            Zoeken
          </button>
        </div>
        {showAdvancedSearch && (
          <div
            id="advanced-search-box"
            data-testid={"zoeken.geavanceerd-testid"}
            className={`flex offset-2 col-8 d-flex flex-row `}
          >
            <div className={classNames("w-100 p-2", classes.boxie)}>
              <div className="form-group form-row ">
                <div className={"col-3"}>Geboortedatum</div>
                <div className={"col-3"}>Postcode</div>
                <div className={"col-6"}>Woonplaats</div>
              </div>
              <div className={classNames("form-group form-row mb-3 input_100")}>
                <div className={"col-3"}>
                  <DateInput name="zoeken.geboortedatum" />
                </div>
                <div className={"col-3"}>
                  <PostcodeInput name="zoeken.postcode" />
                </div>
                <div className={"col-6"}>
                  <TextInput name="zoeken.woonplaats" />
                </div>
              </div>
              <div className="form-group form-row ">
                <div className={"col-6"}>Adviseur</div>
                <div className={"col-6"}>Vestiging</div>
              </div>
              <div className="form-group form-row ">
                <div className={"col-6"}>
                  <RadioInputGroup
                    className="d-block"
                    name="zoeken.optieDossiers"
                    layout={Direction.Horizontal}
                    options={[
                      { label: "Mijn dossiers", value: "0" },
                      { label: "Alle dossiers", value: "1" }
                    ]}
                  />
                </div>
                <div className={"col-6"}>
                  <SelectInput options={vestigingenOptions} name="zoeken.vestiging" />
                </div>
              </div>
            </div>
          </div>
        )}

        {(loading || !initialRequest || values.zoeken.zoekresultatenMetToegangsrechten.length > 0) && (
          <div className="col-12 mt-4">
            <div className={classnames("card card-adviesbox", classes.searchcard)}>
              <button
                type="button"
                className={classnames(classes.button_container, "close")}
                onClick={resetResults}
                id="zoekresultaten-verwijderen"
                title="zoekresultaten-verwijderen"
              >
                <span aria-hidden="true">×</span>
                <span className="sr-only">Close</span>
              </button>
              {
                <>
                  <div className="form-group form-row mb-3 pr-2" style={{ marginTop: "-30px" }}>
                    <div className="col align-self-start">
                      <h3>
                        {values.totalNumberOfResults} klant
                        {values.totalNumberOfResults > 1 ? "en" : ""} gevonden
                      </h3>
                    </div>
                    <div className="col align-self-end">
                      <LabeledSelectInput
                        caption="Sorteren op:"
                        name="zoeken.sorteren"
                        onChange={event => /* istanbul ignore next */ {
                          setFieldValue("zoeken.sorteren", event.target.value);

                          if (validSearchInput()) {
                            /* eslint-disable-next-line @typescript-eslint/no-floating-promises */
                            getMatchingClients(pageNumber, event.target.value as ZoekResultaatSorteerOptions);
                          }
                        }}
                        options={[
                          {
                            label: "Naam (A-Z)",
                            value: ZoekResultaatSorteerOptions.AchternaamOplopend
                          },
                          {
                            label: "Naam (Z-A)",
                            value: ZoekResultaatSorteerOptions.AchternaamAflopend
                          },
                          {
                            label: "Geboortedatum (laag-hoog)",
                            value: ZoekResultaatSorteerOptions.GeboorteDatumOplopend
                          },
                          {
                            label: "Geboortedatum (hoog-laag)",
                            value: ZoekResultaatSorteerOptions.GeboorteDatumAflopend
                          },
                          {
                            label: "Postcode (laag-hoog)",
                            value: ZoekResultaatSorteerOptions.PostcodeOplopend
                          },
                          {
                            label: "Postcode (hoog-laag)",
                            value: ZoekResultaatSorteerOptions.PostcodeAflopend
                          },
                          {
                            label: "Woonplaats (A-Z)",
                            value: ZoekResultaatSorteerOptions.WoonplaatsOplopend
                          },
                          {
                            label: "Woonplaats (Z-A)",
                            value: ZoekResultaatSorteerOptions.WoonplaatsAflopend
                          }
                        ]}
                      />
                    </div>
                  </div>
                  <div>
                    {loading && <div className="loader"></div>}

                    {values.zoeken.zoekresultatenMetToegangsrechten?.length > 0 && !loading && (
                      <>
                        <DataGrid
                          name="zoeken.zoekresultatenMetToegangsrechten"
                          className={`search-table`}
                          minRows={0}
                          loading={false}
                          defaultPageSize={20}
                          resizable={false}
                          columns={ZoekResultatenColumns()}
                          showPagination={false}
                          getTdProps={(
                            _state: any,
                            rowInfo: any,
                            col: any
                          ): {
                            onClick: () => void;
                          } => searchResultRowProps(rowInfo.original, col, rowInfo.index)}
                        />
                        {`Aantal pagina's: ${values.totalNumberOfPages}`}
                        <PaginationComponent
                          currentPage={pageNumber}
                          pages={values.totalNumberOfPages}
                          setPageNumber={pagenr => {
                            setPageNumber(pagenr);

                            if (validSearchInput()) {
                              /* eslint-disable-next-line @typescript-eslint/no-floating-promises */
                              getMatchingClients(pagenr);
                            }
                          }}
                        />
                      </>
                    )}
                  </div>
                </>
              }
              {!loading && !initialRequest && !showTimeoutWarning && values.zoeken.zoekresultaten.length === 0 && (
                <div className={classes.no_result}>Geen klanten gevonden</div>
              )}
              {!loading && showTimeoutWarning && values.zoeken.zoekresultaten.length === 0 && (
                <div className={classes.no_result}>{getZoekenTextResources("TimeoutWarning")}</div>
              )}
              {!loading &&
                !!values.zoeken.zoekresultatenMetToegangsrechten?.length &&
                values.zoeken.zoekresultaten.length > values.zoeken.zoekresultatenMetToegangsrechten.length && (
                  <div className={classes.no_result}>{getZoekenTextResources("MinderToegangsrechtenResultaten")}</div>
                )}
              {!loading &&
                !!values.zoeken.zoekresultaten?.length &&
                !values.zoeken.zoekresultatenMetToegangsrechten?.length && (
                  <div className={classes.no_result}>{getZoekenTextResources("LegeResultatenMetToegangsrechten")}</div>
                )}
            </div>
          </div>
        )}
        <div data-testid="client-modal">
          <SelectedClientModal isModalOpen={isModalOpen} setModalOpen={setModalOpen} />
        </div>
      </div>
    </div>
  );
};

export default connect<ZoekenPropType, ZoekschermState>(withRouter(Zoeken));
