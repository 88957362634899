import React, { ReactElement } from "react";

type Pagination = {
  pages: number;
  currentPage: number;
  setPageNumber: (pagenumber: number) => void;
};

export const PaginationComponent = ({ pages, currentPage, setPageNumber }: Pagination): ReactElement => {
  const totalPages: number[] = [];

  for (let i = 1; (pages || 1) + 1 > i; i++) {
    if (i >= currentPage - 15 && i < currentPage + 15) {
      totalPages.push(i);
    }
  }

  const fnSetPageNumber = setPageNumber;

  return (
    <div data-testid="pagination" className="d-flex p-3 mt-2">
      <div className="ml-auto">
        {pages > 30 && currentPage !== 1 && (
          <span
            className={`cursor-pointer btn-pagination`}
            key={-1}
            data-testid={"to-first-pagination"}
            onClick={() => fnSetPageNumber(1)}
          >
            &lt;
          </span>
        )}

        {totalPages.map(p => (
          <span
            className={`cursor-pointer btn-pagination ${p === currentPage ? "btn-pagination-active" : ""}`}
            key={p}
            data-testid={p + "-pagination"}
            onClick={() => fnSetPageNumber(p)}
          >
            {p}
          </span>
        ))}
        {pages > 30 && currentPage !== pages && (
          <span
            className={`cursor-pointer btn-pagination `}
            key={-2}
            data-testid={"to-last-pagination"}
            onClick={() => fnSetPageNumber(pages)}
          >
            &gt;
          </span>
        )}
      </div>
    </div>
  );
};
