import React, { ReactElement, ReactNode, useState } from "react";
import UserDetailsContext, { defaultUserDetails, Medewerker } from "./user-details-context";
import { userDetailsSchema } from "./user-details-schema";

const setUserDetailsProviderValue = (
  setUserDetails: (userDetails: Medewerker) => void,
  userDetails: Medewerker
): typeof defaultUserDetails => ({
  userDetails,
  setUserDetails
});

const UserDetailsProvider = ({ children }: { children: ReactNode }): ReactElement => {
  const [userDetails, setUserDetails] = useState(userDetailsSchema.default());

  const val = setUserDetailsProviderValue(setUserDetails, userDetails);
  return <UserDetailsContext.Provider value={val}>{children}</UserDetailsContext.Provider>;
};

export default UserDetailsProvider;
