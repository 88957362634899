import * as Yup from "yup";
import { DossierbalkInfo, DossierbalkOutput } from "../../../.generated/forms/formstypes";
import { createMapToUi } from "../../utils/create-map-to-ui";
import { createMapToDl } from "../../utils/create-map-to-dl";

export const omschrijvingSchema = Yup.object({
  externAdviesnummer: Yup.string().default(""),
  korteOmschrijving: Yup.string().default(""),
  langeOmschrijving: Yup.string().default("")
});
export type OmschrijvingType = Yup.InferType<typeof omschrijvingSchema>;

const mapDossierDlEntry2Ui = createMapToUi(omschrijvingSchema).from<DossierbalkInfo>({
  externAdviesnummer: v => v.externeIdentificatie,
  korteOmschrijving: v => v.korteOmschrijving,
  langeOmschrijving: v => v.uitgebreideOmschrijving
});
export const mapDossierDl2Ui = (adviesdossierId: string, data: DossierbalkOutput): OmschrijvingType =>
  mapDossierDlEntry2Ui(data && data.isValid && data.dossierbalkInfo ? data.dossierbalkInfo[adviesdossierId] : null);

export const mapDossierUi2Dl = createMapToDl(omschrijvingSchema).to<DossierbalkInfo>({
  externeIdentificatie: v => v.externAdviesnummer,
  korteOmschrijving: v => v.korteOmschrijving,
  uitgebreideOmschrijving: v => v.langeOmschrijving,
  aanvrager1: _ => null,
  aanvrager2: _ => null
});
