/* istanbul ignore file */
//TODO remove istanbul ignore
import { FieldMap, UiName } from "../../shared/types";
import { target2field } from "../../shared/utils/target-to-field";
// TODO add teh correct documenten from fromtypes
// import {Documenten as DocumentenDlEntry } from "../documenten";

export function mapDlTargetToDocumentUiField(target: string): UiName | null {
  // TODO add DocumentenDlEntry
  const map: FieldMap<any> = {};

  return target2field(map, target);
}
