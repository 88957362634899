import { Schema } from "yup";

/**
 * S = Source
 * D = Destination
 * C = Context
 */

type MapWithContext<S, D, C> = {
  [P in keyof D]-?: (from: S, context: C, index?: number) => D[P] | null | undefined;
};

type MapWithoutContext<S, D> = {
  [P in keyof D]-?: (from: S, index?: number) => D[P] | null | undefined;
};

type Mapper<S, D, C> = Exclude<C, undefined> extends never
  ? (source: S | null | undefined, index?: number) => D
  : (context: C) => (source: S | null | undefined, index?: number) => D;

type CreateMapToUiReturnType<D> = {
  with: <C>() => { from: <S = never>(map: MapWithContext<Exclude<S, null | undefined>, D, C>) => Mapper<S, D, C> };
  from: <S = never>(map: MapWithoutContext<Exclude<S, null | undefined>, D>) => Mapper<S, D, undefined>;
};
export const createMapToUi = <D>(schema: Schema<D>): CreateMapToUiReturnType<D> => {
  const withContext = <S = never, C = undefined>(
    map: MapWithContext<Exclude<S, null | undefined>, D, C>
  ): Mapper<S, D, C> => {
    const mapper = (context: C) => (source: S | null | undefined, index?: number): D => {
      const def = schema.default();
      if (typeof source === "undefined" || source === null) {
        return def;
      }

      const keys = Object.keys(map) as (keyof D)[];
      for (let i = 0; i < keys.length; i++) {
        const prop = keys[i];
        const val = map[prop](source as Exclude<S, null | undefined>, context, index);
        if (typeof val !== "undefined" && val !== null) {
          def[prop] = val;
        }
      }

      return def;
    };

    return mapper as Mapper<S, D, C>;
  };

  const withoutContext = <S = never>(
    map: MapWithoutContext<Exclude<S, null | undefined>, D>
  ): Mapper<S, D, undefined> => {
    const mapper = (source: S | null | undefined, index?: number): D => {
      const def = schema.default();
      if (typeof source === "undefined" || source === null) {
        return def;
      }

      const keys = Object.keys(map) as (keyof D)[];
      for (let i = 0; i < keys.length; i++) {
        const prop = keys[i];
        const val = map[prop](source as Exclude<S, null | undefined>, index);
        if (typeof val !== "undefined" && val !== null) {
          def[prop] = val;
        }
      }

      return def;
    };

    return mapper as Mapper<S, D, undefined>;
  };

  return {
    with: () => ({ from: withContext }),
    from: withoutContext
  };
};
