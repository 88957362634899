import { logErrorToApplicationInsights, SettingsType, SourceApplication } from "adviesbox-shared";
import { User } from "oidc-client";
import { hasJSONResponse } from "../shared//utils/ajax";

export type LogRequestBody = {
  username: string;
  datumTijdOpening: string;
  laatstGeopendSource: SourceApplication;
};

export const logOpenDossier = async (
  settings: SettingsType,
  adviesdossierId: string,
  klantdossierId: string,
  vestigingId: string,
  user: User
): Promise<void> => {
  const url = `${settings.logOrigin}/Vestigingen/${vestigingId}/Klantdossiers/${klantdossierId}/Adviesdossiers/${adviesdossierId}/Openingen`;
  const body: LogRequestBody = {
    username: user.profile.name ?? "unknown-username",
    datumTijdOpening: new Date().toLocaleString("en-US", { timeZone: "Europe/Amsterdam" }),
    laatstGeopendSource: SourceApplication.documenten
  };

  const rsp = await fetch(url, {
    method: "POST",
    headers: {
      authorization: `${user.token_type} ${user.access_token}`,
      "Ocp-Apim-Subscription-Key": settings.OcpApimSubscriptionKey,
      "Content-Type": "application/json",
      vestigingId
    },
    body: JSON.stringify(body)
  });

  if (!rsp.ok || !hasJSONResponse(rsp)) {
    logErrorToApplicationInsights(Error(`Fout bij aanroep van web - api '${url}'. Resultaat bevat geen JSON.`));
    return;
  }

  const json = await rsp.json();

  if (!json.isValid) {
    logErrorToApplicationInsights(Error(`Fout bij aanroep van web - api '${url}'.Input is niet valid.`));
  }
};
