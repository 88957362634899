import React, { ReactElement, useEffect, useRef, useState } from "react";
import classes from "./fileviewer.module.scss";
import { Icon, Pdf } from "adviesbox-shared";

type FileViewerProps = {
  index?: number;
  file: File;
  preview?: boolean;
  filename?: string;
  extension?: "pdf" | "docx" | "png" | "jpg" | "jpeg" | undefined;
  onFocusLoss?: (e: React.ChangeEvent<HTMLInputElement>, i: number) => void;
  removeItem?: (fileName: string) => void;
};

export const FileViewer = ({
  index,
  file,
  onFocusLoss,
  removeItem,
  extension,
  preview = false,
  filename
}: FileViewerProps): ReactElement => {
  const viewer = useRef(null);
  const [pdfComp, setPdfComp] = useState<ReactElement | null>(null);
  const [name, setName] = useState<string>(file.name);

  useEffect(() => {
    setPdfComp(
      <Pdf
        key={`pdf-${file.name}`}
        preview={preview}
        filename={filename}
        blob={file as Blob}
        extension={extension ?? (file.name.split(".").pop() as "pdf" | "docx" | "png" | "jpg" | "jpeg" | undefined)}
        viewer={viewer.current}
      />
    );
  }, [file, extension, preview, filename]);
  return (
    <>
      {!preview ? (
        <>
          <div key={file.name} id={file.name} ref={viewer} style={{ width: "21cm", height: "29cm" }}></div>
          {pdfComp && pdfComp}{" "}
        </>
      ) : (
        <div key={`preview-${index}`} className={classes.previewContainer}>
          <div className="d-flex justify-content-between">
            <span className={classes.fileTitle}>{file.name}</span>
            <span onClick={() => removeItem && removeItem(file.name)} id="close" data-testid={"close"}>
              <Icon name="close" />
            </span>
          </div>

          <div className={classes.innerContainer}>
            <div key={file.name} id={file.name} ref={viewer} style={{ height: "240px", width: "280px" }}></div>
            {pdfComp && pdfComp}
            <div className={classes.formContainer}>
              <div className={`d-flex `}>
                <span className={`col-6 ${classes.padding}`}>Naam</span>
                <input
                  onChange={e => setName(e.target.value)}
                  onBlur={e => onFocusLoss && onFocusLoss(e, index ?? 0)}
                  className={`${classes.inputStyling}`}
                  value={name}
                  data-testid={"name-input"}
                />
              </div>
              <div className={`d-flex `}>
                <span className={`col-6 ${classes.padding}`}>Bestandsformaat</span>
                <span className={`col-6 ${classes.padding}`}>{file.type.split("/")[1] ?? ""}</span>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
