/* istanbul ignore file */
import { LocalDate } from "@js-joda/core";
import {
  BerekenCurrencyButton,
  Card,
  CardWrapper,
  LabeledBevestigingInput,
  LabeledCheckBox,
  LabeledCurrencyInput,
  LabeledDateInput,
  LabeledNumberInput,
  LabeledPercentageInput,
  LabeledPostcodeInput,
  LabeledRadioInput,
  LabeledTextInput,
  ProgressBar,
  ResetButton,
  StepType,
  TextInput
} from "adviesbox-shared";
import { Form, FormikProps } from "formik";
import React, { ReactElement, useState } from "react";
import { Button } from "react-bootstrap";
import * as Yup from "yup";
import { ComponentsOverviewState } from "./ComponentState";
import { Debug } from "./shared/components/formik/Debug";
import LabeledHdnKeuzelijst from "./shared/components/hdn-keuzelijst/LabeledHdnKeuzelijst";
import { Direction } from "./shared/types";
import { withAdviesboxFormik } from "./shared/utils/with-adviesbox-formik";

const movieOptions = [
  { label: "Hypotheek product", value: "0" },
  { label: "Arbeidsongeschiktheid", value: "1" },
  { label: "Verzekeringproduct ", value: "2" }
];

const steps: { [key: string]: StepType } = {
  step1: { component: <></>, type: "full" },
  step2: { component: <></>, type: "full" },
  step3: { component: <></>, type: "full" },
  step4: { component: <></>, type: "full" },
  step5: { component: <></>, type: "full" }
};

const ComponentsOverview = ({ values }: FormikProps<ComponentsOverviewState>): ReactElement => {
  const progressState = useState(1);
  const [progress, setProgress] = progressState;

  const nextStep = (): void => {
    setProgress(progress + 1);
  };

  return (
    <Form>
      <CardWrapper>
        <Card title="Componenten overzicht:" className="all-cards-same-size">
          <div>
            <LabeledTextInput caption="Voornaam XL:" name="person.firstName" tooltip="hier komt tekst" />

            <LabeledTextInput caption="Voornaam Large:" name="person.firstName" fieldSize="l" />

            <LabeledTextInput caption="Voornaam Medium:" name="person.firstName" fieldSize="m" />

            <LabeledTextInput caption="Voornaam Small:" name="person.firstName" fieldSize="s" />

            <LabeledTextInput
              caption="Jaar en maand:"
              name="person.firstName"
              type="number"
              fieldSize="s"
              appendChildren={
                <>
                  <span>jaren</span>
                  <TextInput name="person.lastName" type="number" fieldSize="s" appendChildren={<span>maanden</span>} />
                </>
              }
            />

            <LabeledHdnKeuzelijst
              caption="Het land (HDN keuzelijst):"
              name="person.country"
              keuzelijst="LandType"
              berichtSoortType="AX"
            />

            <LabeledHdnKeuzelijst
              caption="Object type (HDN keuzelijst):"
              name="woning.objectType"
              keuzelijst="CodeObjectType2"
              berichtSoortType="AX"
            />

            <LabeledRadioInput
              caption="Dit is een vraag"
              name="person.rechtsbijstand"
              options={movieOptions}
              layout={Direction.Vertical}
            />

            <LabeledCurrencyInput name="person.currency" caption="Bedrag" />

            <LabeledCurrencyInput name="person.currency" caption="Bedrag (twee decimalen)" decimalen={2} />

            <LabeledCurrencyInput name="person.currency" caption="Bedrag" readonly />

            <LabeledCurrencyInput name="person.currency" caption="Bedrag (twee decimalen)" decimalen={2} readonly />

            <LabeledCurrencyInput
              caption="Bedrag met berekenknop"
              name={`person.currency`}
              disabled={values.person.currencyCalc}
              appendChildren={
                <BerekenCurrencyButton name={`person.currencyCalc`} berekenen={values.person.currencyCalc} />
              }
            />

            <LabeledCurrencyInput
              caption="Bedrag met resetknop"
              name="person.currency"
              appendChildren={<ResetButton name="person.currency" defaultValue={50} />}
            />
            <LabeledNumberInput name="person.currency" caption="number" />
            <LabeledCheckBox caption="Label Checkbox" name="person.checkboxId" />

            <LabeledDateInput caption="Dit is een datumveld:" name="person.birthday" />

            <LabeledBevestigingInput caption="Dit is een vraag" name="person.rechtsbijstand" />

            <LabeledPostcodeInput caption="Postcode" name="person.adres.postcode" />

            <LabeledPercentageInput caption="Percentage veld 1 decimaal" name="person.percentage-input" decimalen={1} />

            <LabeledPercentageInput caption="Percentage veld 3 decimaal" name="person.percentage-input" decimalen={3} />

            <button className="btn btn-primary">Save me</button>
          </div>
        </Card>
      </CardWrapper>
      <CardWrapper>
        <Card title="Progress bar">
          <div className={"px-5 mx-5 py-5"}>
            <ProgressBar steps={steps} progressState={progressState} />
          </div>

          <div className="d-flex">
            {progress < Object.keys(steps).length && (
              <Button onClick={nextStep} className={"ml-auto"}>
                Next
              </Button>
            )}
          </div>
        </Card>
      </CardWrapper>
      <Debug />
    </Form>
  );
};

export default withAdviesboxFormik<{}, ComponentsOverviewState>({
  mapPropsToValues: (): ComponentsOverviewState => ({
    person: {
      firstName: "Maurice",
      country: "NL",
      currency: 123456,
      percentage: 44,
      birthday: LocalDate.of(1982, 4, 4),
      checkboxId: false,
      currencyCalc: true,
      adres: { postcode: "1111 AA" }
    },
    woning: {
      objectType: "02"
    }
  }),
  validationSchema: Yup.object({})
})(ComponentsOverview);
