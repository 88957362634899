import { LogRequestBody } from "./zoekscherm-schema";

import { LocalDate } from "@js-joda/core";
import { SettingsType, logErrorToApplicationInsights, mapLocalDateToString } from "adviesbox-shared";
import { User } from "oidc-client";
import { ZoekDossierInput, ZoekResultaatSorteerOptions, ZoekresultaatOutput } from "../../.generated/forms/formstypes";
import { hasJSONResponse } from "../../shared/utils/ajax";

export const searchClients = async (
  query: string,
  settings: SettingsType,
  vestigingId: string,
  user: User,
  signal: AbortSignal,
  pageNumber: number,
  soorteerOptie: ZoekResultaatSorteerOptions,
  advanced: {
    woonplaats: string | null;
    postcode: string | null;
    geboortedatum: LocalDate | null;
    doorzoekAlleAdviezen: boolean;
    zoekInVestiging: string | null;
  }
): Promise<ZoekresultaatOutput | null> => {
  const url = `${settings.klantdossiersFormsOrigin}/Adviesdossiers/Zoeken`;

  const searchInput: ZoekDossierInput = {
    aantalResultatenPerPagina: 20,
    doorzoekAlleAdviezen: advanced.doorzoekAlleAdviezen,
    geboorteDatum: mapLocalDateToString(advanced.geboortedatum),
    zoekNaamInput: query,
    postcode: advanced.postcode,
    woonplaats: advanced.woonplaats,
    zoekVestigingId: advanced.zoekInVestiging,
    zoekResultaatSorteerOptions: soorteerOptie,
    naamDelen: null,
    paginaNummer: pageNumber
  };

  try {
    const rsp = await fetch(url, {
      method: "POST",
      headers: {
        authorization: `${user.token_type} ${user.access_token}`,
        "Ocp-Apim-Subscription-Key": settings.OcpApimSubscriptionKey,
        "Content-Type": "application/json;charset=UTF-8",
        vestigingId
      },
      signal,
      body: JSON.stringify(searchInput)
    });

    if (!(rsp.ok && hasJSONResponse(rsp)))
      throw Error(`Fout bij aanroep van web-api '${url}'. Resultaat bevat geen JSON.`);

    const json = await rsp.json();

    if (!json.isValid) throw Error(`Fout bij aanroep van web-api '${url}'. Input is niet valid.`);

    return json;
  } catch (err) {
    if (err instanceof Error && err.name !== "AbortError") throw err;
  }
  return null;
};

export const logOpenDossier = async (
  settings: SettingsType,
  adviesdossierId: string,
  klantdossierId: string,
  vestigingId: string,
  user: User
): Promise<void> => {
  const url = `${settings.logOrigin}/Vestigingen/${vestigingId}/Klantdossiers/${klantdossierId}/Adviesdossiers/${adviesdossierId}/Openingen`;
  const body: LogRequestBody = {
    username: user.profile.name ?? "unknown-username",
    datumTijdOpening: new Date()
  };

  const rsp = await fetch(url, {
    method: "POST",
    headers: {
      authorization: `${user.token_type} ${user.access_token}`,
      "Ocp-Apim-Subscription-Key": settings.OcpApimSubscriptionKey,
      "Content-Type": "application/json",
      vestigingId
    },
    body: JSON.stringify(body)
  });

  if (!rsp.ok || !hasJSONResponse(rsp)) {
    logErrorToApplicationInsights(Error(`Fout bij aanroep van web - api '${url}'. Resultaat bevat geen JSON.`));
    return;
  }

  const json = await rsp.json();
  if (!json.isValid) {
    throw Error(`Fout bij aanroep van web - api '${url}'.Input is niet valid.`);
  }
};
