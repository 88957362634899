import * as Yup from "yup";
import { InferType } from "yup";
import { CellInfo } from "react-table-6";
import { Dossierstatus, ZoekResultaatSorteerOptions } from "../../.generated/forms/formstypes";
import { yupEnum, yupNullableEnum } from "../../shared/utils/yup-enum";
import adresSchema from "../../shared/generic-parts/adres/schema";
import { localDateTimeSchema, nullableLocalDateSchema } from "adviesbox-shared";

export enum SearchCategoriesAdvies {
  Naam = "Naam",
  Postcode = "Postcode",
  Woonplaats = "Woonplaats",
  Geboortedatum = "Geboortedatum"
}

export const zoekenAdviesdossierSchema = Yup.object({
  klantdossierId: Yup.string(),
  adviesdossierId: Yup.string(),
  laatstBewerktDatum: Yup.string().nullable(),
  laatstBewerktTijd: Yup.string().nullable(),
  geboorteData: Yup.string().nullable(),
  postcode: Yup.string().nullable(),
  woonplaats: Yup.string().nullable(),
  vestiging: Yup.string().nullable(),
  vestigingId: Yup.string().nullable(),
  aanleiding: Yup.string().nullable(),
  omschrijving: Yup.string(),
  status: Yup.string().nullable(),
  bemiddelingDossierStatus: yupNullableEnum(Dossierstatus).default(null)
});

export const zoekResultaatClientSchema = Yup.object({
  klantdossierId: Yup.string(),
  laatstGeopend: localDateTimeSchema.nullable(),
  klant: Yup.string(),
  aantalDossiers: Yup.number(),
  adviseur: Yup.string(),
  adviseurId: Yup.string().nullable(),
  vestiging: Yup.string(),
  geboorteData: Yup.array(nullableLocalDateSchema.nullable()).default([]),
  postcode: Yup.string().nullable(),
  woonplaats: Yup.string().nullable(),
  vestigingId: Yup.string().nullable(),
  adviesDossiers: Yup.array(zoekenAdviesdossierSchema),
  naamAanvrager: Yup.string(),
  naamPartner: Yup.string().nullable()
});

export const zoekenSchema = Yup.object({
  vestiging: Yup.string().default("ALL"),
  optieDossiers: Yup.string().default("0"),
  sorteren: yupEnum(ZoekResultaatSorteerOptions).default(ZoekResultaatSorteerOptions.AchternaamOplopend),
  geboortedatum: nullableLocalDateSchema,
  woonplaats: Yup.string().nullable(),
  postcode: Yup.string().nullable(),
  searchValue: Yup.string().default(""),
  searching: Yup.boolean().default(false),
  zoekresultaten: Yup.array(zoekResultaatClientSchema).default([]),
  zoekresultatenMetToegangsrechten: Yup.array(zoekResultaatClientSchema).default([]),
  selectedClient: zoekResultaatClientSchema.nullable().default(null)
});

export const laatsteDossierSchema = Yup.object({
  adviesdossierId: Yup.string(),
  laatstGeopend: localDateTimeSchema,
  klant: Yup.string(),
  aanleiding: Yup.string(),
  omschrijving: Yup.string(),
  status: Yup.string()
});

export const laatsteDossiersSchema = Yup.object({
  aantalDossiers: Yup.number().default(0),
  laatsteDossiers: Yup.array(laatsteDossierSchema).default([]),
  inView: Yup.number().default(10)
});

export const zoekschermSchema = Yup.object({
  zoeken: zoekenSchema,
  laatsteDossiers: laatsteDossiersSchema,
  recentGeopendError: Yup.boolean().default(false),
  totalNumberOfPages: Yup.number().default(0),
  totalNumberOfResults: Yup.number().default(0)
});

export type ZoekschermState = InferType<typeof zoekschermSchema>;
export type ZoekenType = InferType<typeof zoekenSchema>;
export type ZoekResultaatType = InferType<typeof zoekResultaatClientSchema>;
export type ZoekenAdviesDossierType = InferType<typeof zoekenAdviesdossierSchema>;
export type LaatsteDossierType = InferType<typeof laatsteDossierSchema>;
export type LaatsteDossiersType = InferType<typeof laatsteDossiersSchema>;

export enum TableColumnId {
  DeleteClientButton = "DeleteClientButton",
  DeleteDossierButton = "DeleteDossierButton",
  Aanleiding = "Aanleiding",
  Status = "Status",
  Vestiging = "Vestiging"
}

export type LogRequestBody = {
  username: string;
  datumTijdOpening: Date;
};

export type DeleteClientButtonProps = {
  deleteClientFunc: (klantdossierId: string) => () => void;
  cellInfo: CellInfo;
};

export const vestigingSchema = Yup.object({
  id: Yup.string()
    .nullable()
    .default(null),
  naam: Yup.string().default(""),
  isHoofdvestiging: Yup.boolean().default(false),
  waarschuwingsBericht: Yup.string()
    .default("")
    .nullable(),
  telefoonnummer: Yup.string().default(""),
  emailadres: Yup.string().default(""),
  bezoekadres: adresSchema,
  postadres: adresSchema.nullable().default(null),
  afwijkendPostadresTonen: Yup.boolean()
    .default(false)
    .nullable(),
  afmRegistratienummer: Yup.string().default(""),
  platformId: Yup.string()
    .nullable()
    .default(""),
  hasChanged: Yup.boolean().default(false),
  isDeleted: Yup.boolean().default(false),
  putChanges: Yup.boolean().default(false),
  postChanges: Yup.boolean().default(false),
  savedChanges: Yup.boolean().default(false),
  triggeredByModal: Yup.boolean().default(false)
});

export type VestigingType = Yup.InferType<typeof vestigingSchema>;

export const vestigingenSchema = Yup.object({
  vestigingen: Yup.array(vestigingSchema).default([])
});
export type VestigingenType = Yup.InferType<typeof vestigingenSchema>;
