/* istanbul ignore file */

import {
  AuthContext,
  ErrorMessageBox,
  getAppLocation,
  getNaamAdviseur,
  HttpErrorPage,
  SettingsContext,
  useUpscope
} from "adviesbox-shared";
import React, { ReactElement, useContext } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import DocumentenBeheerAjax from "../documentenBeheerScherm/documentBeheerscherm-ajax";
import Drawer from "../shared/components/drawer/Drawer";
import { Title } from "../shared/components/title/title";
import TopNavigation from "../shared/components/topnav-dossier/TopNavigation";
import UserDetailsContext from "../shared/user-details/user-details-context";

const AuthenticatedDocumentBeheerAppWithDrawer = (): ReactElement => {
  const settingsContext = useContext(SettingsContext);
  const linkToPortal = getAppLocation(window.location.origin, "DOC", "POR", settingsContext.baseUrls);
  const { user } = useContext(AuthContext);
  const { userDetails } = useContext(UserDetailsContext);
  useUpscope(user?.profile.name, [getNaamAdviseur(userDetails)]);

  return (
    <div data-testid="authenticated-document-beheer-app-with-router">
      <Drawer>
        <Title appName="Documentbeheer" />
        <TopNavigation />
        <ErrorMessageBox />
        <Switch>
          <Route
            path="/vestiging/:vestiging/adviesdossier/:adviesdossier/documentbeheer"
            component={DocumentenBeheerAjax}
          />
          {/*istanbul ignore next */ process.env.NODE_ENV === "development" && (
            <Route render={(): ReactElement => <Redirect to="/zoeken/0ac20c96-ae06-4e5d-acb8-d32b27677c78" />} />
          )}

          <Route>
            <HttpErrorPage status={404} returnUrl={linkToPortal} />
          </Route>
        </Switch>
      </Drawer>
    </div>
  );
};

export default AuthenticatedDocumentBeheerAppWithDrawer;
