import {
  AdviesBoxColumn,
  createSpanWithId,
  DataGrid,
  Icon,
  LoadingSpinner,
  ModalButton,
  useRequestInit,
  cleanFilename,
  getFormattedDate,
} from "adviesbox-shared";
import { useFormikContext } from "formik";
import React, { ReactElement, useEffect, useState } from "react";
import { CellInfo } from "react-table-6";
import { DownloadResultApiModel } from "../../.generated/bemiddeling/bemiddelingtypes";
import { ISWSideEffects } from "../../shared/components/isw-side-effects/isw-side-effects";
import { PaginationComponent } from "../../shared/components/pagination/pagination";
import classes from "../documentBeheer.module.scss";
import { asyncDocumentBeheerSideEffects } from "../infra/determine-documentbeheer-async-side-effects";
import {
  bestandsIndelingTypes,
  documentBeheerSchema,
  DocumentenBeheerItem,
  DocumentenBeheerState
} from "../infra/documentBeheer-schema";
import { PreviewModal } from "../previewModal/previewModal";
import "./documentenBeheerDataGrid.scss";

export interface NavigationList {
  path: string;
  map: string;
}
export interface PathInModalProps {
  folderPath: string;
  folderPathAsString: string;
}

export interface PaginationData {
  currentPage: number;
  totalPages: number | undefined;
}

interface DocumentBeheerGridProps {
  minified?: boolean | null;
  pathInModal?: (data: PathInModalProps) => void;
  foldersOnly?: boolean;
}

const DocumentenBeheerDataGrid = ({
  minified,
  pathInModal,
  foldersOnly = false
}: DocumentBeheerGridProps): ReactElement => {
  const [navigation, setNavigation] = useState<NavigationList[]>([{ path: `documentenbeheer`, map: "Documenten" }]);
  const [currentPath, setCurrentPath] = useState<string>("documentenbeheer");
  const [loadingSpinner] = useState<boolean>(false);
  const [paginationPages, setPaginationPages] = useState<PaginationData>({
    currentPage: 0,
    totalPages: undefined
  });
  const { values, getFieldProps, setFieldValue } = useFormikContext<DocumentenBeheerState>();

  const updatePaginationData = (data: PaginationData): void => {
    setPaginationPages(data);
  };

  const navigate = (m: DocumentenBeheerItem, i: number, columnId: string): { onClick: () => void } => ({
    onClick: (): void => {
      if (m.bestandsIndeling !== bestandsIndelingTypes.MAP || columnId === "viewmodal")
        /* istanbul ignore next */ return;
      updatePaginationData({ ...paginationPages, currentPage: 0 });
      !navigation.find(navItem => navItem.path === `${currentPath}[${i}]children`) &&
        setNavigation(navigation => [...navigation, { path: `${currentPath}[${i}]children`, map: m.bestandsnaam }]);
      setCurrentPath(current => `${current}[${i}]children`);
    }
  });

  const navigateBreadCrumbs = (target: string): void => {
    updatePaginationData({ ...paginationPages, currentPage: 0 });
    navigation.map((navItem, i): number => {
      if (navItem.path === target) {
        setNavigation(navigation => navigation.slice(0, i + 1));
        setCurrentPath(navItem.path);
      }
      return i;
    });
  };

  const getBreadCrumbs = (): ReactElement => {
    return (
      <>
        {navigation.length > 1
          ? navigation.map((navItem, i) => (
              <span
                key={navItem.path}
                data-testid={`breadcrumbs${i}`}
                className={navigation.length - 1 !== i ? classes.pointer : classes.bold}
                onClick={() => navigateBreadCrumbs(navItem.path)}
              >{`${navItem.map}${navigation.length - 1 !== i ? " >" : ""} `}</span>
            ))
          : "Documenten"}
      </>
    );
  };

  useEffect(() => {
    values.documentenInView &&
      updatePaginationData({
        currentPage: 0,
        totalPages: Math.floor(getFieldProps(currentPath).value.length / values.documentenInView)
      });
  }, [values.documentenInView, getFieldProps, currentPath]);
 


  const documentNameCell = (c: CellInfo): ReactElement => {
    let icon = c.original.bestandsIndeling.toLowerCase();
    const bestandBlobLocatie = c.original.bestandBlobLocatie;

    if (icon === "map") {
      icon = "folder";
    }
    if (icon === "png" || icon === "jpg" || icon === "jpeg") {
      icon = "picture";
    }
    return (
      <div
        className={`${classes.entry_name} ${
          c.original.bestandsIndeling === bestandsIndelingTypes.MAP ? classes.pointerFolder : "d-flex"
        }`}
      >
        {c.original.bestandsIndeling === bestandsIndelingTypes.MAP ? (
          <>
            <Icon name={icon} alt={c.original.bestandsnaam} />
            <span style={{ marginLeft: "10px" }}>{c.original.bestandsnaam}</span>
          </>
        ) : (
          <ModalButton
            key={c.original.klantsamenstellingDocumentId}
            className={`${classes.button_hover}`}
            parent={`${currentPath}[${c.index}]`}
            size="lg"
            content={
              <div className={`${classes.entry_name}`}>
                <Icon name={icon} alt={c.original.bestandsnaam} />
                <span style={{ marginLeft: "10px" }}>{c.original.bestandsnaam}</span>
              </div>
            }
          >
            <PreviewModal path={`${currentPath}[${c.index}]`} blobLocatie={bestandBlobLocatie} />
          </ModalButton>
        )}
      </div>
    );
  };

  const ModalIconComponent = (c: CellInfo): ReactElement => {
    const [downloading, setDownloading] = useState(false);
    const { params, settings, requestInit } = useRequestInit();
    const bestandBlobLocatie = c.original.bestandBlobLocatie;
    const bestandLocatie = `${cleanFilename(c?.original?.bestandsnaam)}.${c?.original?.bestandsIndeling}`;

    /* istanbul ignore next */
    const downloadImage = async (): Promise<void> => {
      if (downloading) return;
      setDownloading(true);
      const urlCreation = bestandLocatie
        ? `${settings.klantdossiersBemiddelingOrigin}/Document/klantsamenstelling/${params.adviesdossier}/download/${bestandBlobLocatie}/${bestandLocatie}`
        : `${settings.klantdossiersBemiddelingOrigin}/Document/klantsamenstelling/${params.adviesdossier}/download/${bestandBlobLocatie}`;
      const res = await fetch(urlCreation, requestInit);
      const json: DownloadResultApiModel = await res.json();
      const fileLocation = json.fileDownloadUrls?.pop();

      setDownloading(false);

      if (!fileLocation) return;
      window.open(fileLocation);
    };

    return (
      <div className={`d-flex h-100`}>
        {c.original.bestandsIndeling === bestandsIndelingTypes.MAP ? (
          <></>
        ) : c.original.bestandsIndeling === bestandsIndelingTypes.DOCX ? (
          <button type="button" className={`cursor-pointer download-btn ${classes.button_fixed_icon}`} onClick={downloadImage}>
            <Icon name="download" alt="download" multiColor={true} />
          </button>
        ) : (
          <ModalButton
            parent={`${currentPath}[${c.index}]`}
            size="lg"
            content={
              <Icon
                className={`${classes.button_height}`}
                name="documentpreview"
                alt="documentpreview"
                multiColor={true}
              />
            }
          >
            <PreviewModal path={`${currentPath}[${c.index}]`} blobLocatie={bestandBlobLocatie} />
          </ModalButton>
        )}
      </div>
    );
  };
  let columns: AdviesBoxColumn[] = [
    {
      id: "bestandsnaam",
      Header: "Bestandsnaam",
      accessor: "bestandsnaam",
      Cell: documentNameCell,
      minWidth: 220,
      config: {
        readonly: true
      }
    },
    {
      id: "laatstGewijzigd",
      Header: "Aanmaakdatum",
      accessor: "laatstGewijzigd",
      Cell: (c): ReactElement => createSpanWithId(c.index, 1, c.original.laatstGewijzigd ? getFormattedDate(c.original.laatstGewijzigd) : "")
    },
    {
      id: "kenmerk",
      Header: "Kenmerk",
      accessor: "kenmerk",
      Cell: "TextInput",
      config: {
        readonly: true
      }
    },
    {
      id: "bestandsIndeling",
      Header: "Bestandsindeling",
      accessor: "bestandsIndeling",
      Cell: "TextInput",
      config: {
        readonly: true
      }
    },
    {
      id: "viewmodal",
      accessor: "viewmodal",
      Cell: ModalIconComponent,
      className: classes.height_fix_gridcell,
      width: 55
    }
  ];

  /* istanbul ignore else */
  if (minified) {
    columns = columns.filter(col => col.id !== "kenmerk" && col.id !== "viewmodal");
  }
  /* istanbul ignore else */
  if (pathInModal) {
    pathInModal({
      folderPath: currentPath,
      folderPathAsString: navigation
        .map(item => item.map)
        .slice(1)
        .join("/")
    });
  }

  return loadingSpinner ? (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <LoadingSpinner size="S" />
    </div>
  ) : (
    <>
      <h2>{getBreadCrumbs()}</h2>
      <ISWSideEffects<DocumentenBeheerState> async={asyncDocumentBeheerSideEffects({ currentPath })} />
      {getFieldProps(currentPath).value?.length > 0 ? (
        <DataGrid
          columns={columns}
          name={currentPath}
          filtered={foldersOnly ? [{ id: "bestandsIndeling", value: bestandsIndelingTypes.MAP }] : []}
          className={classes.overflow_fix}
          getTdProps={(_state: any, row: any, column: any): { onClick: () => void } | undefined => {
            return navigate(row.original, row.index, column.id);
          }}
          sortable={true}
          sortedCallback={e => setFieldValue(currentPath, e)}
          validationSchema={documentBeheerSchema}
          page={paginationPages.currentPage}
          pageSize={values.documentenInView}
          showPagination={true}
          PaginationComponent={PaginationComponent(true, "documentenInView", "Documenten", updatePaginationData)}
        />
      ) : (
        <div className={`d-flex flex-column justify-content-center ${classes.empty_folder} w-100`}>
          <span
            className={`${classes.empty_line} ${classes.empty_line_upper}`}
          >{`In deze map zijn nog geen documenten opgenomen.`}</span>
        </div>
      )}
    </>
  );
};

DocumentenBeheerDataGrid.displayName = "DocumentenBeheerDataGrid";

export default DocumentenBeheerDataGrid;
