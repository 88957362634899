import { ErrorPage, PageLoading, SettingsType, withErrorBoundary } from "adviesbox-shared";
import React, { ReactElement } from "react";
import { useAdviesboxData } from "../shared/hooks/use-adviesbox-data";
import DocumentenBeheerscherm from "./documentBeheerscherm";
import { mapDlTargetToDocumentUiField } from "./infra/map-documenten-dl-target-to-ui-field";
import { mapDocumentsDlToUi } from "./infra/map-documenten-dl-to-ui";

/*istanbul ignore file */

type RouteParams = {
  vestiging: string;
  adviesdossier: string;
};
const createUrl = (s: SettingsType, p: RouteParams): string =>
  `${s.klantdossiersBemiddelingOrigin}/Document/klantsamenstelling/${p.adviesdossier}`;

export const DocumentenBeheerAjax = (): ReactElement => {
  const { loading, error, data } = useAdviesboxData(
    createUrl,
    p => p.adviesdossier,
    mapDocumentsDlToUi,
    () => {},
    mapDlTargetToDocumentUiField
  );

  if (error) {
    return <ErrorPage error={error} />;
  }

  if (loading || !data) {
    return <PageLoading />;
  }

  return <DocumentenBeheerscherm {...data} />;
};
DocumentenBeheerAjax.displayName = "DocumentenBeheerAjax";
export default withErrorBoundary(DocumentenBeheerAjax);
