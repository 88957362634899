/* istanbul ignore file */

import { MenuInfo, MenuOutput } from "../../.generated/forms/formstypes";
import { useContext, useEffect } from "react";

import { RouteParams } from "../paramrouting/paramrouting-context";
import useAbortableFetch from "use-abortable-fetch";
import { useRequestInit, ForceRerenderContext } from "adviesbox-shared";

export type UseMenuInfoDataResult = {
  error: null | Error;
  data: MenuInfo | null;
  loading: boolean;
};

export const useMenuInfoData = (reloadCount: number): UseMenuInfoDataResult => {
  const { requestInit, settings, params } = useRequestInit<RouteParams>();
  const url = params.adviesdossier
    ? `${settings.klantdossiersFormsOrigin}/Adviesdossiers/${params.adviesdossier}/Menu#${reloadCount}`
    : null;
  const loadingDone = useContext(ForceRerenderContext);
  const { loading, error, data } = useAbortableFetch<MenuOutput>(url, requestInit);

  useEffect((): void => {
    if (!loading && !!data && loadingDone) {
      loadingDone();
    }
  }, [loading, data, loadingDone]);

  if (typeof data === "string") {
    return { error: new Error("Fout bij het laden van instellingen-tarieven data"), data: null, loading: false };
  }

  const menuInfo =
    params.adviesdossier && data && typeof data !== "string" && data.isValid && data.menuInfo
      ? data.menuInfo[params.adviesdossier]
      : null;

  return { error, data: menuInfo ? menuInfo : null, loading };
};
