import { createGetTextResources } from "../../shared/utils/text-resources-algemeen";
/* istanbul ignore file */

export const getZoekenTextResources = createGetTextResources({
  /*:TOOLTIP:*/
  MinderToegangsrechtenResultaten: "Er zijn meer dossiers onder deze klant waarvoor je geen rechten hebt.",
  LegeResultatenMetToegangsrechten: "Er zijn dossiers onder deze klant waarvoor je geen rechten hebt.",
  TimeoutWarning:
    "Het zoeken naar resultaten duurde te lang. Maak alsjeblieft je zoektermen specifieker en probeer het opnieuw."
});
