import {
  AuthContext,
  ErrorPage,
  logErrorToApplicationInsights,
  PageLoading,
  SettingsContext,
  withErrorBoundary
} from "adviesbox-shared";
import React, { ReactElement, useContext, useMemo } from "react";
import { RouteComponentProps } from "react-router";
import useAbortableFetch from "use-abortable-fetch";
import { RecentGeopendOutput } from "../.generated/forms/formstypes";
import { Title } from "../shared/components/title/title";
import { RouteParams } from "../shared/paramrouting/paramrouting-context";
import ParamRouteProvider from "../shared/paramrouting/paramrouting-provider";
import { mapZoekschermDlToUi } from "./infra/map-zoekscherm";
import { zoekschermSchema } from "./infra/zoekscherm-schema";
import Zoekscherm from "./zoekscherm";
import classes from "./zoekscherm.module.scss";

const ZoekschermAjax = ({ match }: RouteComponentProps<RouteParams>): ReactElement => {
  const { user } = useContext(AuthContext);
  const settings = useContext(SettingsContext);
  const { vestiging: vestigingId } = match.params;
  const url = `${settings.klantdossiersFormsOrigin}/Documentdossiers/recent?aantal=20`;
  const requestInit = useMemo(
    (): RequestInit => ({
      headers: {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        authorization: `${user!.token_type} ${user!.access_token}`,
        vestigingId,
        "Ocp-Apim-Subscription-Key": `${settings.OcpApimSubscriptionKey}`
      }
    }),
    [user, settings.OcpApimSubscriptionKey, vestigingId]
  );

  const { loading, error, data } = useAbortableFetch<RecentGeopendOutput>(url, requestInit);

  if (!user) return <ErrorPage error={new Error("Missing AuthContext")} />;
  if (error) {
    logErrorToApplicationInsights(Error(`Fout bij aanroep van web - api '${url}'. Error: ${error}, Data: ${data}.`));
  }

  if (typeof data === "string") {
    logErrorToApplicationInsights(
      Error(`Fout bij aanroep van web - api '${url}'. Resultaat bevat invalid data: string`)
    );
  }

  if (loading || !data)
    return (
      <div className={classes.loader_correction}>
        <PageLoading />
      </div>
    );

  const mappedData = mapZoekschermDlToUi(data);
  if (!mappedData) {
    logErrorToApplicationInsights(Error("Zowel defaults als opgehaalde data heeft geen waarde"));
  }
  const zoekschermData =
    mappedData && !error
      ? { ...mappedData, recentGeopendError: false }
      : { ...zoekschermSchema.default(), recentGeopendError: true };
  return (
    <ParamRouteProvider route={match.params}>
      <Title altTitle={"Zoeken"} appName="Documenten" />
      <Zoekscherm {...zoekschermData} />
    </ParamRouteProvider>
  );
};

ZoekschermAjax.displayName = "ZoekschermAjax";
export default withErrorBoundary(ZoekschermAjax);
