import { NewOutput } from "adviesbox-shared";
import { getIn } from "formik";
import { KlantsamenstellingdocumentApiModel } from "../../.generated/bemiddeling/bemiddelingtypes";
import {
  createISWAsyncSideEffect,
  initISWAsyncSideEffect
} from "../../shared/components/isw-side-effects/create-isw-helpers";
import { bestandsIndelingTypes, DocumentenBeheerState } from "./documentBeheer-schema";

/*istanbul ignore file*/
export const updatefileAsync = createISWAsyncSideEffect<DocumentenBeheerState, { currentPath: string }>(
  async ({ draft, settings, params, fetchData }) => {
    const file = draft.updatedFile;
    if (!file?.bestandLocatie || !file.klantsamenstellingDocumentId) {
      draft.updatedFile = null;
      return;
    }
    await fetchData<KlantsamenstellingdocumentApiModel>({
      url: `${settings.klantdossiersBemiddelingOrigin}/Document/klantsamenstelling`,
      method: "PUT",
      body: JSON.stringify({
        adviesdossierId: params.adviesdossier,
        klantsamenstellingdocumentId: file.klantsamenstellingDocumentId,
        bestandLocatie: file.bestandLocatie,
        bestandNaam: file.bestandsIndeling === bestandsIndelingTypes.MAP ? null : file.bestandsNaamInclusiefIndeling
      })
    }).then(() => {
      draft.updatedFile = null;
    });
  }
);

export const removefileAsync = createISWAsyncSideEffect<DocumentenBeheerState, { currentPath: string }>(
  async ({ draft }) => {
    const file = draft.fileToBeRemoved;
    if (file && !file.klantsamenstellingDocumentId) {
      draft.fileToBeRemoved = null;
      return;
    }
  }
);

export const createNewFolderAsync = createISWAsyncSideEffect<DocumentenBeheerState, { currentPath: string }>(
  async ({ draft, settings, params, fetchData, formik, context }) => {
    const folder = draft.newFolder;
    if (!folder?.bestandLocatie) {
      draft.newFolder = null;
      return;
    }
    await fetchData<NewOutput>({
      url: `${settings.klantdossiersBemiddelingOrigin}/Document/createFolder/${params.adviesdossier}`,
      method: "POST",
      body: JSON.stringify({
        folderLocatie: folder.bestandLocatie
      })
    }).then(e => {
      const newFolderId = e.id;
      folder.klantsamenstellingDocumentId = newFolderId;
      const path = formik.getFieldProps(context.currentPath);
      const currentVal = getIn(formik.values, path.name);
      const update = [...currentVal, JSON.parse(JSON.stringify(folder))];
      formik.setFieldValue(path.name, update);
      draft.newFolder = null;
    });
  }
);

export const asyncDocumentBeheerSideEffects = initISWAsyncSideEffect<DocumentenBeheerState, { currentPath: string }>(
  ({ has, runAsync, curr, context }): void => {
    if (has.updatedFile.changed) {
      return runAsync(updatefileAsync(context));
    }
    if (has.fileToBeRemoved.changed && has.fileToBeRemoved !== null) {
      return runAsync(removefileAsync(context));
    }
    if (has.newFolder.changed) {
      return runAsync(createNewFolderAsync(context));
    }
  }
);
