import { MenuConfigType } from "adviesbox-shared";
import { RouteParams } from "../shared/paramrouting/paramrouting-context";

export const menuConfig: (params: RouteParams) => MenuConfigType[] = params => [
  {
    name: "berekeningen",
    lists: [
      {
        name: "documentbeheer",
        title: "Documentbeheer",
        link: `/vestiging/${params.vestiging}/adviesdossier/${params.adviesdossier}/documentbeheer`,
        icon: "adviesbox"
      }
    ]
  }
  // Logboek momenteel nog niet bruikbaar
  // {
  //   name: "logboek",
  //   title: "Logboek",
  //   lists: [
  //     {
  //       name: "logboek",
  //       title: "Logboek",
  //       icon: "menulogboek"
  //     }
  //   ]
  // }
];
