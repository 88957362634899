import { AuthContext, SettingsContext } from "adviesbox-shared";
import OpenDossierLogContext, { logger } from "./open-dossier-log-context";
import React, { ReactElement, ReactNode, useCallback, useContext, useRef } from "react";
import { logOpenDossier } from "./open-dossier-log-api";

const getOpenDossierLogContextValue = (
  setter: (
    adviesDossierId: string | null,
    vestigingId: string | null,
    klantDossierId: string | null
  ) => void | ((adviesDossierId: string | null, vestigingId: string | null, klantDossierId: string | null) => void)
): logger => ({
  setAdviesDossierId: setter
});

const OpenDossierLogProvider = ({ children }: { children: ReactNode }): ReactElement => {
  const prevAdviesDossierId = useRef<string | null>(null);
  const { user } = useContext(AuthContext);
  const settings = useContext(SettingsContext);
  const logAdviesDossierId = useCallback(
    (adviesDossierId: string | null, klantDossierId: string | null, vestigingId: string | null) => {
      if (prevAdviesDossierId.current === adviesDossierId || !user || !klantDossierId || !vestigingId) return;
      prevAdviesDossierId.current = adviesDossierId;

      if (!adviesDossierId) return;
      /* eslint-disable-next-line @typescript-eslint/no-floating-promises */
      logOpenDossier(settings, adviesDossierId, klantDossierId, vestigingId, user);
    },
    [settings, user]
  );

  const val = getOpenDossierLogContextValue(logAdviesDossierId);

  return <OpenDossierLogContext.Provider value={val}>{children}</OpenDossierLogContext.Provider>;
};

export default OpenDossierLogProvider;
