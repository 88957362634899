import {
  KlantsamenstellingdocumentApiModel,
  DocumentTypeOptions,
  KlantsamenstellingdocumentenApiModel
} from "../../.generated/bemiddeling/bemiddelingtypes";
import { createMapToUi } from "../../shared/utils/create-map-to-ui";

import { documentenBeheerItem, FileType, DocumentenBeheerState } from "./documentBeheer-schema";
import { mapStringToLocalDate } from "adviesbox-shared";

const getFolderIndex = (folderTree: FileType[], folder: string): number =>
  folderTree?.findIndex((f: FileType) => f.bestandsnaam === folder);

const isDesignatedFolder = (folderTree: FileType[], foldername: string, folder: FileType): void => {
  const index = folderTree?.findIndex((f: FileType) => f.bestandsnaam === foldername);

  if (index >= 0) {
    const {
      klantsamenstellingDocumentId,
      laatstGewijzigd,
      laatstGewijzigdString,
      medewerkerId,
      medewerkerNaam
    } = folder;
    folderTree[index] = {
      ...folderTree[index],
      klantsamenstellingDocumentId,
      laatstGewijzigd,
      laatstGewijzigdString,
      medewerkerId,
      medewerkerNaam
    };
    return;
  }
  folderTree.push({
    ...folder,
    bestandsIndeling: "Map",
    bestandsnaam: foldername,
    children: []
  });
};
const determineFolderContent = (
  folders: string[],
  placedFile: FileType,
  currentItem: FileType,
  bestandLocatie: string | null,
  mapToFolder: (file: FileType, documentBeheer: FileType[], location?: string | null) => FileType[]
): void => {
  if (folders.length > 1) {
    placedFile.bestandLocatie = folders.slice(1).join("/");
    mapToFolder(placedFile, currentItem.children, bestandLocatie);
  }

  if (folders.length === 1 && placedFile.documentType !== DocumentTypeOptions.Folder) {
    currentItem.children.push({ ...placedFile, bestandLocatie });
  }
};

export const mapToFolder = (file: FileType, documentBeheer: FileType[], location: string | null = null): FileType[] => {
  const folderTree = documentBeheer;
  const bestandLocatie = location ? location : file.bestandLocatie;
  const placedFile = file;
  const folders = placedFile.bestandLocatie?.split("/");
  if (folders && folders.length > 1 && !folders[0]) {
    folders.shift();
  }
  if (file.documentType === DocumentTypeOptions.Folder && folders && folders?.length > 1 && !folders[1]) {
    folders?.pop();
    isDesignatedFolder(folderTree, folders[0], placedFile);
  }

  if (folderTree && folders && !folders[0].includes(".")) {
    const index = getFolderIndex(folderTree, folders[0]);

    if (folderTree[index]) {
      const currentItem = folderTree[index];
      determineFolderContent(folders, placedFile, currentItem, bestandLocatie, mapToFolder);
    }
    if (folders.length > 1 && !folderTree[index]) {
      folderTree.push({
        ...documentenBeheerItem.default(),
        bestandsIndeling: "Map",
        bestandsnaam: folders[0],
        bestandsNaamInclusiefIndeling: folders[0],
        children: []
      });

      const currentItem = folderTree[folderTree.length - 1];
      determineFolderContent(folders, placedFile, currentItem, bestandLocatie, mapToFolder);
    }
  }

  if (!folders || folders[0].includes(".")) {
    folderTree?.push({ ...placedFile, bestandLocatie });
  }
  return folderTree;
};

export const dl2uiDocumenten = createMapToUi(documentenBeheerItem).from<KlantsamenstellingdocumentApiModel>({
  bestandsnaam: v => {
    if (v.bestandNaam) {
      const bestandNaamArray = v.bestandNaam.split(".");
      if (bestandNaamArray.length > 1) bestandNaamArray.pop();
      return bestandNaamArray.toString().replace(/^\d+\s/g, "");
    }

    return "";
  },
  bestandsNaamInclusiefIndeling: v => v.bestandNaam,
  laatstGewijzigd: v =>  mapStringToLocalDate(v.uploadDatum),
  kenmerk: v => v.documentKenmerken,
  bestandLocatie: v => v.bestandLocatie,
  originLocatie: v => v.bestandLocatie,
  documentType: v => v.documentType,
  laatstGewijzigdString: v => null,
  bestandsIndeling: v => {
    if (v.bestandNaam) {
      const bestandNaamArray = v.bestandNaam.split(".");
      return bestandNaamArray[bestandNaamArray.length - 1];
    }

    return "";
  },
  bestandBlobLocatie: v => v.bestandBlobLocatie,
  klantsamenstellingDocumentId: v => v.klantsamenstellingDocumentId,
  medewerkerId: v => v.medewerkerId,
  medewerkerNaam: v => v.medewerkerNaam,
  children: v => [],
  isNew: v => false
});



export function mapDocumentsDlToUi(
  adviesdossierId: string,
  data: KlantsamenstellingdocumentenApiModel
): DocumentenBeheerState | null {
  const documenten = data.klantsamenstellingdocumenten;
  if (!documenten) {
    return null;
  }
 
 const documentBeheer:any=[];
 const mappedDocumenten = documenten.map(c=> dl2uiDocumenten(c)) || []
 mappedDocumenten.forEach(file => mapToFolder(file, documentBeheer));
 
  return {
    documentenbeheer: documentBeheer,
    updatedFile: null,
    fileToBeRemoved: null,
    newFolder: null,
    documentenInView: 10
  };
}
