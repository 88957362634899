import {
  MedewerkerExKlantdossierRechtenOrganisatie,
  MedewerkerExKlantdossierRechtenVestigingen,
  MedewerkerKlantdossierRechtenOrganisatie,
  MedewerkerKlantdossierRechtenVestigingen
} from "../../../.generated/licenties/licentiestypes";
import { ZoekResultaatType } from "../../infra/zoekscherm-schema";

export const filterRecordsBasedOnRights = (
  resultaat: ZoekResultaatType[],
  gebrukersId: string,
  vestigingId: string,
  gebrukersRechtenVestigingen:
    | MedewerkerKlantdossierRechtenVestigingen
    | MedewerkerExKlantdossierRechtenVestigingen
    | null,
  gebrukersRechtenOrganisatie:
    | MedewerkerKlantdossierRechtenOrganisatie
    | MedewerkerExKlantdossierRechtenOrganisatie
    | null
): ZoekResultaatType[] => {
  if (gebrukersId && gebrukersRechtenOrganisatie && gebrukersRechtenVestigingen) {
    if (gebrukersRechtenOrganisatie === "Geen") {
      if (gebrukersRechtenVestigingen === "Geen") {
        return resultaat.filter(v => v.adviseurId === gebrukersId);
      } else {
        return resultaat.filter(v => v.adviseurId === gebrukersId || v.vestigingId === vestigingId);
      }
    }
  }
  return resultaat;
};
