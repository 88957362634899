import { AuthContext } from "adviesbox-shared";
import React, { ReactElement } from "react";
import AuthenticatedDocumentBeheerApp from "./AuthenticatedDocumentBeheerApp";
import UnauthenticatedDocumentBeheerApp from "./UnauthenticatedDocumentBeheerApp";

const DocumentBeheerApp = (): ReactElement => {
  const { user } = React.useContext(AuthContext);

  if (user) {
    return <AuthenticatedDocumentBeheerApp />;
  }

  return <UnauthenticatedDocumentBeheerApp />;
};

DocumentBeheerApp.displayName = "DocumentBeheerApp";

export default DocumentBeheerApp;
