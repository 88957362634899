import { Adres } from "../../.generated/forms/formstypes";
import { AdresInput, Vestiging, VestigingenOutput } from "../../.generated/licenties/licentiestypes";
import { mapAdresDlNaarUi } from "../../shared/generic-parts/adres/map-dl-2-ui";
import { AdresType } from "../../shared/types";
import { createMapToUi } from "../../shared/utils/create-map-to-ui";
import { vestigingenSchema, VestigingenType, vestigingSchema } from "./zoekscherm-schema";

const mapVestiging = createMapToUi(vestigingSchema)
  .with<string>()
  .from<Vestiging>({
    id: (_, id) => id,
    naam: v => v.naam,
    bezoekadres: v => mapAdresInputDlNaarUi(v.bezoekadres),
    emailadres: v => v.emailadres,
    isHoofdvestiging: v => v.isHoofdvestiging,
    postadres: v =>
      v.postadres?.postcode && /* istanbul ignore next*/ v.postadres?.huisnummer
        ? mapAdresInputDlNaarUi(v.postadres)
        : null,
    telefoonnummer: v => v.telefoonnummer,
    platformId: v => v.platformId,
    afmRegistratienummer: v => v.afmRegistratienummer,
    waarschuwingsBericht: () => null,
    afwijkendPostadresTonen: v => (v.postadres?.postcode && v.postadres?.huisnummer ? true : false),
    hasChanged: () => false,
    isDeleted: () => false,
    putChanges: () => false,
    postChanges: () => false,
    savedChanges: () => false,
    triggeredByModal: () => false
  });

const mapVestigingen = createMapToUi(vestigingenSchema).from<VestigingenOutput>({
  vestigingen: v => {
    const vestigingArray = [];
    for (const key in v.vestigingen) {
      /* istanbul ignore else */
      if (v.vestigingen.hasOwnProperty(key)) {
        vestigingArray.push(mapVestiging(key)(v.vestigingen[key]));
      }
    }

    return vestigingArray;
  }
});

/* istanbul ignore next */
export function mapAdresInputDlNaarUi(valuesDl?: AdresInput | null): AdresType {
  if (!valuesDl) return mapAdresDlNaarUi(null);

  const parsedHuisnummer = parseInt(valuesDl.huisnummer);
  const huisnummer = typeof parsedHuisnummer === "number" && !isNaN(parsedHuisnummer) ? parsedHuisnummer : null;
  const landcode = valuesDl.landCode ?? valuesDl.land.toLowerCase() === "nederland" ? "NL" : null;

  const converted: Adres = {
    postcode: valuesDl.postcode,
    huisnummer: huisnummer,
    landCode: landcode,
    locatie: "",
    plaats: valuesDl.plaats,
    straat: valuesDl.straatnaam,
    toevoeging: valuesDl.toevoeging
  };

  return mapAdresDlNaarUi(converted);
}

export function mapVestigingDlToUi(data: VestigingenOutput): VestigingenType | null {
  const vestigingen = data && data.isValid && data.vestigingen ? data : null;

  if (vestigingen) {
    return mapVestigingen(vestigingen);
  }

  return null;
}
