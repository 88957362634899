import React, { ReactElement, useContext } from "react";
import { connect, FormikContextType } from "formik";

import { withRouter, RouteComponentProps } from "react-router";
import { SelectInput, FilterType, SortType, DataGrid } from "adviesbox-shared";
import classes from "./laatste-dossiers.module.scss";
import ParamRouteContext from "../../shared/paramrouting/paramrouting-context";
import { laatsteDossierColumns } from "./laatste-dossiers-columns";
import { ZoekschermState, LaatsteDossierType } from "../infra/zoekscherm-schema";

const LaatsteDossiers = ({
  formik: { values },
  showDossiers,
  history
}: {
  formik: FormikContextType<ZoekschermState>;
  showDossiers: boolean;
} & RouteComponentProps): ReactElement => {
  const { vestiging } = useContext(ParamRouteContext);

  const rowProps = (
    dossier: LaatsteDossierType
  ): {
    onClick: () => void;
  } => ({
    /* istanbul ignore next */
    onClick: (): void => {
      history.push(`/vestiging/${vestiging}/adviesdossier/${dossier.adviesdossierId}/documentbeheer`);
    }
  });

  return (
    <>
      {showDossiers && values.laatsteDossiers.laatsteDossiers && !!values.laatsteDossiers.laatsteDossiers.length && (
        <div data-testid="test-table" className={classes.table_container}>
          <DataGrid
            name={"laatsteDossiers.laatsteDossiers"}
            className="search-table"
            sortable={true}
            filterable={true}
            filterConfig={{
              klant: FilterType.Text,
              aanleiding: FilterType.Checkbox,
              status: FilterType.Checkbox
            }}
            sortConfig={{
              laatstGeopend: SortType.Descending
            }}
            initialSort="laatstGeopend"
            minRows={0}
            loading={false}
            resizable={false}
            columns={laatsteDossierColumns}
            showPagination={true}
            pageSize={values.laatsteDossiers.inView}
            getTrProps={(
              _state: any,
              rowInfo: any
            ): {
              onClick: () => void;
            } => rowProps(rowInfo.original)}
            PaginationComponent={(): ReactElement => (
              <div className={"d-flex mt-3 px-2"}>
                <span className={classes.mt_02}>Laatste</span>
                <SelectInput
                  className={"mx-2 px-0"}
                  fieldSize={"s"}
                  name={"laatsteDossiers.inView"}
                  options={[
                    { label: "10", value: "10" },
                    { label: "15", value: "15" },
                    { label: "20", value: "20" }
                  ]}
                />
                <span className={classes.mt_02}>geopende dossiers tonen</span>
              </div>
            )}
          />
        </div>
      )}
      {showDossiers &&
        (!values.laatsteDossiers.laatsteDossiers || values.laatsteDossiers.laatsteDossiers.length === 0) && (
          <div className={`d-flex flex-column align-items-center justify-content-center ${classes.no_results}`}>
            <h2 className={classes.no_results_title}>Er zijn geen laatst geopende dossiers</h2>
            {!values.recentGeopendError && (
              <span className={classes.no_results_sub}>Zoek een dossier of klant met bovenstaande balk.</span>
            )}
            {values.recentGeopendError && (
              <span className={classes.no_results_sub}>
                Er gaat iets mis met het ophalen van je laatst geopende dossiers, probeer het later opnieuw.
              </span>
            )}
          </div>
        )}
    </>
  );
};

export default connect<{ showDossiers: boolean }, ZoekschermState>(withRouter(LaatsteDossiers));
