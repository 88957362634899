import React, { ReactElement, useState } from "react";
import {
  FormFirstFocus,
  Icon,
  PlatformFoutenSamenvatting,
} from "adviesbox-shared";
import { Form } from "formik";
import LaatsteDossiers from "./laatste-dossiers/laatste-dossiers";
import Zoeken from "./zoeken/zoeken";
import classes from "./zoekscherm.module.scss";
import { withAdviesboxFormik } from "../shared/utils/with-adviesbox-formik";
import { ZoekschermState, zoekschermSchema } from "./infra/zoekscherm-schema";
import TopNavbar from "../shared/components/topnav-dossier/TopNavbar";
import { Debug } from "../shared/components/formik/Debug";

const Zoekscherm = (): ReactElement => {
  const [showDossiers, setShowDossiers] = useState(false);
  const toggleShowDossiers = (): void => {
    setShowDossiers(!showDossiers);
  };

  return (
    <div className={classes.loader_correction}>
      <TopNavbar home={true} />
      <FormFirstFocus>
        <Form>
          <div className={`${classes.zoekscherm_header} py-4`}>
            <div className="mb-2 d-flex">
              <h1 className="mx-auto">Adviesbox Documentbeheer</h1>
            </div>
            <Zoeken />
          </div>
          <PlatformFoutenSamenvatting />
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className={`card card-adviesbox ${classes.zoeken_cards}`}>
                  <button
                    type="button"
                    onClick={toggleShowDossiers}
                    className={`${classes.clickable} d-flex flex-row flex-wrap`}
                    id="toggle-show-dossiers"
                  >
                    <h2 className={"m-0 align-self-center"}>
                      Mijn laatst geopende dossiers
                    </h2>
                    <div className={`${classes.icon} ml-auto`}>
                      <>
                        {!showDossiers && (
                          <Icon name="chevron" alt="arrow-down" />
                        )}
                        {showDossiers && (
                          <div className={classes.chevron_up}>
                            <Icon name="chevron" alt="arrow-up" />
                          </div>
                        )}
                      </>
                    </div>
                    <div
                      className={classes.dossiers}
                      onClick={
                        /* istanbul ignore next */ (e) => e.stopPropagation()
                      }
                    >
                      <LaatsteDossiers showDossiers={showDossiers} />
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <Debug />
        </Form>
      </FormFirstFocus>
    </div>
  );
};

Zoekscherm.displayName = "Zoekscherm";
export default withAdviesboxFormik<ZoekschermState, ZoekschermState>({
  mapPropsToValues: (val): ZoekschermState => val,
  validationSchema: zoekschermSchema,
})(Zoekscherm);
