import { SelectInput } from "adviesbox-shared";
import React, { ReactElement } from "react";
import { TableProps } from "react-table-6";

/*istanbul ignore file*/

export const PaginationComponent = (
  showItemsInViewComponent: boolean,
  path?: string,
  perPageName?: string,
  getPageNumber?: (data: { currentPage: number; totalPages: number }) => void
) => ({ pages, page, onPageChange, onResizedChange }: Partial<TableProps>): ReactElement => {
  const totalPages: number[] = [];

  for (let i = 1; (pages || 1) + 1 > i; i++) {
    totalPages.push(i);
  }

  if (showItemsInViewComponent && onResizedChange) {
    onResizedChange([], "");
  }

  return (
    <div className={`d-flex ${showItemsInViewComponent ? " justify-content-between" : "justify-content-end"}`}>
      {showItemsInViewComponent && path && (
        <div className={"d-flex  pt-4  mt-2"}>
          <SelectInput
            className={"mx-2 px-0"}
            fieldSize={"s"}
            parseValueToNumber
            name={path}
            options={[
              { label: "10", value: "10" },
              { label: "15", value: "15" },
              { label: "20", value: "20" }
            ]}
          />
          <span className={"my-1"}>{perPageName ? perPageName : "items"} per pagina tonen</span>
        </div>
      )}
      <div data-testid="pagination" className="d-flex p-4 mt-2">
        <div className="ml-auto">
          {totalPages.map(p => (
            <span
              className={`cursor-pointer btn-pagination ${p - 1 === page ? "btn-pagination-active" : ""}`}
              key={p}
              data-testid={p + "-pagination"}
              onClick={(): void => {
                if (getPageNumber) {
                  getPageNumber({ currentPage: p - 1, totalPages: totalPages.length - 1 });
                }
                onPageChange && onPageChange(p - 1);
              }}
            >
              {p}
            </span>
          ))}
        </div>
      </div>
    </div>
  );
};
