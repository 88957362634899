import { Zoekresultaat } from "../../../.generated/forms/formstypes";
import { ZoekResultaatType, ZoekenAdviesDossierType } from "../../infra/zoekscherm-schema";
import {
  mapAanleidingen2ReadableString,
  mapStatus2ReadableString,
  getNaamPersoon,
  mapKlantNamen,
  mapGeboorteData
} from "../../infra/map-zoekscherm";
import { mapStringToLocalDateTime } from "adviesbox-shared";

let currentVestigingen: Record<string, boolean> = {};

export const mapZoekresultaten = (resultaat: Zoekresultaat[]): ZoekResultaatType[] => {
  currentVestigingen = {};

  const mapAdviesDossiers = (res: Zoekresultaat): ZoekenAdviesDossierType[] => {
    const mappedDossiers = (res.adviesdossiers || []).map(
      (ad): ZoekenAdviesDossierType => {
        return {
          aanleiding: ad.aanleiding ? mapAanleidingen2ReadableString[ad.aanleiding] : null,
          klantdossierId: res.klantdossierId,
          adviesdossierId: ad.adviesdossierId,
          geboorteData: res.aanvrager1 ? res.aanvrager1.geboortedatum : null,
          postcode: res.postcode || null,
          vestiging: res.naamVestiging,
          vestigingId: res.vestigingId,
          woonplaats: res.woonplaats || null,
          laatstBewerktDatum: ad.laatstBewerktDatum || null,
          laatstBewerktTijd: ad.laatstBewerktTijd || null,
          omschrijving: ad.omschrijving || "",
          status: mapStatus2ReadableString[ad.status],
          bemiddelingDossierStatus: ad?.bemiddelingDossierStatus || null
        };
      }
    );

    return mappedDossiers;
  };

  return resultaat.map(
    (res): ZoekResultaatType => {
      res.naamVestiging && !currentVestigingen[res.naamVestiging] && (currentVestigingen[res.naamVestiging] = true);
      return {
        klantdossierId: res.klantdossierId,
        laatstGeopend:
          res.laatstGeopendDatum && res.laatstGeopendTijd
            ? mapStringToLocalDateTime(res.laatstGeopendDatum,res.laatstGeopendTijd) 
            : null,
        aantalDossiers: (res.adviesdossiers || []).length,
        adviseur: getNaamPersoon(res.adviseur),
        adviseurId: res.adviseur?.adviseurId ?? null,
        klant: res.aanvrager1 ? mapKlantNamen(res.aanvrager1, res.aanvrager2) : "",
        vestiging: res.naamVestiging || "",
        adviesDossiers: mapAdviesDossiers(res),
        naamAanvrager: getNaamPersoon(res.aanvrager1),
        geboorteData: res.aanvrager1 ? mapGeboorteData(res.aanvrager1, res.aanvrager2) : [],
        postcode: res.postcode,
        woonplaats: res.woonplaats,
        vestigingId: res.vestigingId,
        naamPartner:
          res.aanvrager2?.achternaam || res.aanvrager2?.voornamen
            ? getNaamPersoon(res.aanvrager2)
            : res.aanvrager2?.geboortedatum
            ? "Aanvrager\xa02"
            : ""
      };
    }
  );
};
export const getCurrentVestigingen = (): string[] => {
  return Object.keys(currentVestigingen);
};
