import React, { ReactElement } from "react";
import { CardWrapper, Card, FormFirstFocus, PlatformFoutenSamenvatting } from "adviesbox-shared";
import { Form } from "formik";
import DocumentenBeheerDataGrid from "./datagrid/documentenBeheerDataGrid";
import { documentBeheerSchema, DocumentenBeheerProps, DocumentenBeheerState } from "./infra/documentBeheer-schema";
import { withAdviesboxFormik } from "../shared/utils/with-adviesbox-formik";
import { Debug } from "../shared/components/formik/Debug";

const DocumentenBeheerscherm = (): ReactElement => {
  return (
    <FormFirstFocus>
      <Form>
        <CardWrapper className="px-3">
          <div className="text-container">
            <h2>Documentbeheer</h2>
          </div>
        </CardWrapper>
        <PlatformFoutenSamenvatting />
        <CardWrapper className="px-3 master-detail-card flex-grow-1" maxRowCount={4}>
          <Card title="" className="w-xl-100 w-lg-100 w-md-50 w-50">
            <DocumentenBeheerDataGrid />
          </Card>
        </CardWrapper>
        <Debug />
      </Form>
    </FormFirstFocus>
  );
};

DocumentenBeheerscherm.displayName = "DocumentenBeheerscherm";
export default withAdviesboxFormik<DocumentenBeheerState, DocumentenBeheerState>({
  mapPropsToValues: (e: DocumentenBeheerProps): DocumentenBeheerState => e,
  validationSchema: documentBeheerSchema
})(DocumentenBeheerscherm);
